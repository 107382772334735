import { FormikProps } from "formik";
import Fuse from "fuse.js";
import { BlockData, IDataSource } from "types";

export function formikHelper<T, key extends keyof T>(
  formik: FormikProps<T>,
  value: key,
  helpertext?: string
): { helpertext: string; status: "error" | "default" } {
  const error = formik.touched[value] && (formik.errors[value] as string);
  return {
    status: formik.touched[value] && formik.errors[value] ? "error" : "default",
    helpertext: error || helpertext || "",
  };
}

export const BACKEND = process.env["REACT_APP_BACKEND_URL"] as string;

export const convertArrayToObjectOfObjects = <T>(dataSources: Partial<T>[]) => {
  const objectOfObjects: any = {};
  dataSources.forEach((item: any, index: number) => {
    objectOfObjects[index] = item;
  });
  return objectOfObjects;
};

export const convertObjectsToObjectsOfObjectsWithUUIDs = <T extends object>(objects: T[]): { [key: string]: T & { uuid: string } } => {
  const result: { [key: string]: T & { uuid: string } } = {};
  objects.forEach((obj) => {
    const uuid = crypto.randomUUID();
    result[uuid] = {
      ...obj,
      uuid,
    };
  });
  return result;
};

export const fuzzySearch = <T>(key: string, query: string, data: Partial<T>[]): Partial<T>[] => {
  const fuse = new Fuse(data, {
    keys: [key],
    threshold: 0.3,
    location: 0,
    distance: 100,
    minMatchCharLength: 1,
    isCaseSensitive: false,
    includeScore: false,
  });

  const results = fuse.search(query);
  return results.map((result) => result.item);
};

export const validateBlockData = (blocksData: { [key: string]: Partial<BlockData> }): boolean => {
  for (const blockId in blocksData) {
    const block = blocksData[blockId];

    if (block.result_format === "table") {
      if (!block.table_row_description || !block.table_columns) {
        return true;
      }
    }

    if (block.result_format === "chart") {
      if (!block.chart_instruction || !block.chart_type) {
        return true;
      }
    }

    if (!block.result_style || !block.result_format || !block.title || !block.query || !block.data_source_id) {
      return true;
    }
  }

  return false;
};

export const createColors = (arrayLength: number) => {
  const colors = [];
  const hueStep = 360 / arrayLength;

  for (let i = 0; i < arrayLength; i++) {
    const hue = i * hueStep;
    const saturation = 80; // Adjust saturation as needed
    const lightness = 50; // Adjust lightness as needed
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    colors.push(color);
  }

  return colors;
};

export type ReportGraphDataItemType = {
  x: string[];
  y: string[];
};
;

export const transformData = (data: ReportGraphDataItemType) => {
  const labels = data?.x;

  const colors = createColors(data?.x?.length);

  const datasets = [
    {
      data: data?.y,
      backgroundColor: colors,
      hoverOffset: 4,
    },
  ];

  return {
    labels,
    datasets,
  };
};
