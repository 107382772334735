import axios from "axios";
import {
  ICreateReport,
  IBlockAPIResponse,
  IDataSourcesAPIResponse,
  IReportsAPIResponse,
  IEditBlockPayload,
  ISingleBlockAPIResponse,
  ICreateBlockPayload,
  ICreateBlock,
  ISingleBlockResultAPIResponse,
} from "types";

export const BASE_URL = process.env["REACT_APP_BACKEND_URL"] as string;

export const API = axios.create({
  baseURL: BASE_URL,
});

export const createReportApi = async (data: ICreateReport) => {
  const res = await API.post("reports", data);
  return res.data;
};

export const fetchReportsApi = async (): Promise<IReportsAPIResponse> => {
  const res = await API.get("reports");
  return res.data;
};

export const fetchDataSourcesApi = async (): Promise<IDataSourcesAPIResponse> => {
  const res = await API.get("/data-sources/all");
  return res.data;
};

export const fetchBlocksApi = async (id: string): Promise<IBlockAPIResponse> => {
  const res = await API.get(`/reports/${id}/blocks`);
  return res.data;
};

export const editBlockResultApi = async (data: IEditBlockPayload) => {
  const { content, reportId, blockId } = data;
  const res = await API.patch(`/reports/${reportId}/blocks/${blockId}/result`, {
    content,
  });
  return res.data;
};

export const fetchBlockApi = async (reportId: string, blockId: string): Promise<ISingleBlockAPIResponse> => {
  const res = await API.get(`/reports/${reportId}/blocks/${blockId}`);
  return res.data;
};

export const createBlockResultApi = async (data: ICreateBlockPayload) => {
  const { report_id, blocks_data } = data;

  const res = await API.post(`/reports/${report_id}/blocks-bulk`, {
    blocks_data,
  });
  return res.data;
};

export const uploadDataSource = async (formData: FormData) => {
  const res = await API.post(`/data-sources/files`, formData);

  return res.data;
};

export const uploadReportDataSource = async (formData: FormData) => {
  const res = await API.post(`/data-sources/files/`, formData);
  return res.data;
};

export const fetchBlockResultApi = async (reportId: string, blockId: string, isRedoing: boolean): Promise<ISingleBlockResultAPIResponse> => {
  const url = isRedoing ? `/reports/${reportId}/blocks/${blockId}/result?is_redoing=${isRedoing}` : `/reports/${reportId}/blocks/${blockId}/result`;
  const res = await API.get(url);
  return res.data;
};

export const updateBlockApi = async (data: ICreateBlock) => {
  const { report_id, block_id } = data;
  const res = await API.put(`/reports/${report_id}/blocks/${block_id}`, {
    ...data,
  });
  return res.data;
};

export const chatQueryApi = async (data: any) => {
  const { report_id, block_id, session_id, query } = data;
  const res = await API.post(`/reports/${report_id}/blocks/${block_id}/queries`, {
    session_id: session_id || null,
    query,
  });
  return res.data;
};

export const fetchBlockImageApi = async (data: any) => {
  const { reportId, blockId } = data;
  const res = await API.get(`/reports/${reportId}/blocks/${blockId}/image`);
  return res.data;
};

export const deleteReportApi = async (report_ids: number[]) => {
  const res = await API.delete(`/reports`, {
    data: { report_ids },
  });
  return res.data;
};

export const fetchBlockReferencesApi = async (data: any) => {
  const { reportId, blockId } = data;
  const res = await API.get(`/reports/${reportId}/blocks/${blockId}/references`);
  return res.data;
};

export const getReportDataSourcesApi = async (reportId: string) => {
  const res = await API.get(`/reports/${reportId}/data-sources`);
  return res.data;
};

export const getReportDetails = async (reportId: string) => {
  const res = await API.get(`/reports/${reportId}`);
  return res.data;
};
