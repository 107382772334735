export const paths = {
  reports: {
    root: "/reports",
    id: (id: string | number) => `/reports/${id}`,
  },
  "data-sources": {
    root: "/data-sources",
    id: (id: string | number) => `/data-sources/${id}`,
  },
  blocks: {
    root: "/blocks",
    create: (id: string | number) => `/${id}/create`,
    edit: (reportId: string | number, blockId: string | number) => `/${reportId}/edit/${blockId}`,
  },
};
