import { MdAdd } from "react-icons/md";
import { Button } from "./Button";
import Typography from "./Typography";
import { useState } from 'react';
import { CreateReport } from 'pages/new-reports/create-report';
import { Modal } from './Modal';



export default function EmptyReportsPage() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <div className="flex flex-col gap-10 items-center max-w-[459px]">
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)} open={modalOpen} variant="full" children={<CreateReport onClose={() => setModalOpen(false)} />} />
      )}
      <div className="flex flex-col gap-3 items-center">
        <Typography variant="display3xl" weight="bold" className="bg-gradient-to-br from-text-grey-200 to-text-grey-700 bg-clip-text text-transparent">
          Hello Jide
        </Typography>
        <Typography variant="h6" weight="regular">
          What would you like to analyze today?
        </Typography>
      </div>
      <Button icon={<MdAdd />} title={"Create a Report"} onClick={() => setModalOpen(true)} />
    </div>
  );
}
