import { csv_icon, edit_note_icon, pdf_icon, send_icon, xlsx_icon } from "assets/svg";
import { Button } from "components";
import Typography from "components/Typography";
import { FaArrowLeftLong, FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import { TfiDownload } from "react-icons/tfi";
import {
  BASE_URL,
  useChatQueries,
  useEditBlockResult,
  useFetchBlockImage,
  useFetchBlockReferences,
  useFetchBlockResult,
  useFetchBlocks,
  useGetReportDetails,
} from "services";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { IBlock, IBlockCardProps, IResultFormat, IResultTypes } from "types";
import { LuPencilLine } from "react-icons/lu";
import { BsChatDots } from "react-icons/bs";
import { Text } from "@chakra-ui/react";
import { lazy, useContext, useEffect, useState } from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { LoadingReport } from "./loading-block";
import classNames from "classnames";
import { BACKEND } from "utils";
import { IoMdClose } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";
import Loading from "components/Loading";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { format } from "sql-formatter";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { ChartData, ChartOptions } from "chart.js";
import { AppContext } from 'providers/AppContext';
const CustomChart = lazy(() => import("components/chart/CustomChart"));

Chart.register(CategoryScale);


export function Report() {
  const { togglePdfModal } = useContext(AppContext);

  const param = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { id } = param;
  const { data, isFetching, error } = useFetchBlocks(id as string);

  const { data: reportData, isFetching: isReportDetailsFetching, error: isReportDetailsError } = useGetReportDetails(id as string);

  const displayBlock = data && data?.data?.length > 0 && !isFetching;

  const blocks = data?.data as Partial<IBlock>[];

  const title = reportData && reportData?.data?.report?.title;

  const handleButtonClick = () => {
    togglePdfModal();
  };

  // lg:max-w-[900px] md:max-w-[800px]
  return (
    <div
      className="flex flex-col 
   
     w-full pb-[109px]"
    >
      <div className="flex flex-col">
        <div className="flex gap-3 items-center hover:cursor-pointer mb-8" onClick={() => window.history.back()}>
          <FaArrowLeftLong />{" "}
          <Typography variant="body2" className="text-shade-black">
            Go Back
          </Typography>
        </div>

        <button onClick={handleButtonClick}>damilola</button>

        <div
          className={classNames("flex justify-between items-center", {
            "mb-[64px]": displayBlock,
            "mb-[14px]": !displayBlock,
          })}
        >
          {title && (
            <div className="flex items-center gap-8">
              <Button icon={<GoPlus />} title="New Block" onClick={() => navigate(`/blocks/${id}/create`)} />
              <Typography variant="displayxs" weight="bold" className="text-shade-black">
                {title}
              </Typography>
            </div>
          )}{" "}
          {displayBlock && (
            <div className="flex items-center gap-3">
              <img src={pdf_icon} alt="pdf" />
              <a
                className="w-10 h-10 flex items-center justify-center hover:bg-grey-100 hover:cursor-pointer rounded-md"
                href={`${BACKEND}/reports/${id}/pdf`}
                target="_blank"
              >
                <TfiDownload />
              </a>
            </div>
          )}
        </div>

        {error && (
          <div className="mt-[60px] flex justify-center items-center">
            <Typography variant="displaysm" weight="semibold" className="text-shade-black">
              There was an unexpected error while retrieving blocks
            </Typography>
          </div>
        )}
        {isFetching && (
          <div className="mt-[60px]">
            <LoadingReport />
          </div>
        )}
        {displayBlock && (
          <div>
            <div className="flex flex-col gap-10">
              {blocks.map((block: Partial<IBlock>) => (
                <BlockCard key={block.id} block={block} reportId={id as string} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function BlockCard(props: IBlockCardProps) {
  const navigate = useNavigate();
  const { block, reportId } = props;
  const { title, last_result_created, chart_type, last_result, id, result_type, result_format } = block;
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isRedoing, setIsRedoing] = useState<boolean>(false);
  const [resultType, setResultType] = useState<IResultTypes>();
  const [chartData, setChartData] = useState<ChartData<"pie">>();

  const date = new Date(last_result_created as string).toLocaleDateString();
  const time = new Date(last_result_created as string).toLocaleTimeString();

  const customId = "custom-toast-id";

  const blockId = String(block.id);

  const {
    data: blockResult,
    isFetching: isFetchingBlock,
    error: isBlockError,
    isSuccess: isBlockSuccess,
    refetch,
  } = useFetchBlockResult(reportId, blockId, Boolean(block.last_result), isRedoing);

  const { data: blockReferences, isFetching: isFetchingBlockReferences, error: isBlockReferencesError } = useFetchBlockReferences(reportId, blockId);

  const references = blockReferences?.data?.references;

  const desctucturedLastResultFromBlock = blockResult?.data?.result?.block?.last_result;

  const result = desctucturedLastResultFromBlock ? desctucturedLastResultFromBlock : last_result;

  const chartFormat =
    desctucturedLastResultFromBlock && blockResult?.data?.result?.block?.result_type === "json_string"
      ? blockResult?.data?.result?.block?.result_type
      : result_type;

  console.log(chartFormat, "chartFormat");

  useEffect(() => {
    if (!last_result) {
      refetch();
    }
  }, [last_result]);

  useEffect(() => {
    if (result_type || blockResult?.data?.result?.block?.result_type) {
      setResultType((result_type as IResultTypes) || blockResult?.data?.result?.block?.result_type);
    }
  }, [result_type]);

  useEffect(() => {
    if (chartFormat === "json_string" && result !== "") {
      setChartData(JSON.parse(result as string));
    }
  }, [result]);

  const refresh = () => {
    setIsRedoing(true);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const { editBlock } = useEditBlockResult();
  const handleEditBlock = async (id: number) => {
    setIsEditable(true);
    setIsUpdating(true);
  };

  const updateBlock = async (id: number) => {
    const updatedContent = document.getElementById(String(id))?.innerText;

    const data = {
      content: String(updatedContent),
      reportId,
      blockId: String(id),
    };

    setIsEditable(false);
    editBlock(data, {
      onError: (error, variables, context) => {
        setIsUpdating(false);
        toast.error("Error updating report block", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
      },
      onSuccess: (data, variables, context) => {
        setIsUpdating(false);
        toast.success("Report block successfully updated", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
      },
    });
  };

  const cleanResult = result && result?.replace("/\r?\n|\r/", "");

  const [isOpen, setIsChatOpen] = useState(false);

  const toggleSidebar = () => {
    setIsChatOpen(!isOpen);
  };

  const [displayPdf, setDisplayPdf] = useState(false);

  const displayBlock = last_result === null ? isBlockSuccess : last_result;

  return (
    <div className="flex w-full">
      <div
        className={classNames({
          "w-[50%]": displayPdf,
          "w-full": !displayPdf,
        })}
      >
        <div
          className={`fixed top-0 right-0 h-full w-[40%] bg-shade-white shadow-lg z-40 transform transition-transform duration-300 ease-in-out ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <Chat report_id={reportId} block_id={blockId} previous_response={result as string} onClose={toggleSidebar} />
        </div>

        {isOpen && <div className="fixed inset-0 bg-shade-black bg-opacity-50 z-30" onClick={toggleSidebar}></div>}

        <div className="">
          <div className="text-left mb-2">
            <Typography variant="displaysm" weight="semibold" className="text-shade-black">
              {title}{" "}
            </Typography>
          </div>
          <div className="flex justify-between items-center w-full">
            <Typography variant="textsm">
              Last updated {time} - {date}
            </Typography>

            <div className="flex gap-3 items-center">
              {isEditable ? (
                <div
                  className={classNames("p-[18px] flex gap-2 bg-grey-75 rounded-md items-center justify-center hover:cursor-pointer", {
                    "pointer-events-none bg-grey-200": resultType !== IResultTypes.TEXT,
                  })}
                >
                  <div aria-label="edit complete">
                    {" "}
                    <IoCheckmarkSharp aria-disabled={isUpdating} onClick={() => updateBlock(id as number)} />{" "}
                  </div>
                </div>
              ) : (
                <div
                  className={classNames("p-[14px] flex gap-2 bg-grey-75 rounded-md items-center justify-center hover:cursor-pointer", {
                    "pointer-events-none bg-grey-200 opacity-30": resultType !== IResultTypes.TEXT || result_format === IResultFormat.TABLE,
                  })}
                  onClick={() => handleEditBlock(id as number)}
                >
                  <div aria-label="edit">
                    <img src={edit_note_icon} alt="edit" />
                  </div>
                </div>
              )}

              <div
                className={classNames(
                  "p-[18px] flex gap-2 bg-grey-75 hover:bg-grey-100 rounded-md items-center justify-center hover:cursor-pointer",
                  {
                    "pointer-events-none opacity-50": isFetchingBlock,
                  }
                )}
                onClick={refresh}
              >
                <div aria-label="refresh">
                  <LuRefreshCcw />
                </div>
              </div>
              <div
                className="p-[18px] flex gap-2 bg-grey-75  hover:bg-grey-100 rounded-md items-center justify-center hover:cursor-pointer"
                onClick={() => navigate(`/blocks/${reportId}/edit/${id}`)}
              >
                <div aria-label="update">
                  <LuPencilLine />
                </div>
              </div>
              <div
                className={classNames(
                  "p-[18px] flex gap-2 bg-grey-75 hover:bg-grey-100 rounded-md items-center justify-center hover:cursor-pointer",
                  {
                    "pointer-events-none bg-grey-200": !result,
                  }
                )}
                onClick={toggleSidebar}
              >
                <div aria-label="chat">
                  <BsChatDots />
                </div>
              </div>
            </div>
          </div>
        </div>

        {isFetchingBlock && (
          <div className="mt-[60px]">
            <Loading numberOfLoaders={1} className="bg-grey-200 !h-80" />
          </div>
        )}
        {isBlockError && (
          <div className="my-[60px] py-[60px] flex justify-center items-center border-[1px] border-grey-100">
            <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
              There was an unexpected error while retrieving block
            </Typography>
          </div>
        )}

        <>
          {displayBlock && !isFetchingBlock && (
            <div className="mt-10 w-full text-justify">
              {resultType === IResultTypes.TEXT && result_format !== IResultFormat.TABLE && (
                <Text whiteSpace={"pre-line"} borderWidth={isEditable ? "2px" : "0px"} contentEditable={isEditable} id={String(id)}>
                  {" "}
                  {result}
                </Text>
              )}

              {chartFormat === IResultTypes.JSON_STRING && result && chartData && (
                <>
                  <div className="border-[1px] border-grey-100 rounded-md p-4">
                    <CustomChart chartData={result} chartType={chart_type || "pie_chart"} title={title as string} />
                  </div>
                </>
              )}

              {result_format === IResultFormat.TABLE && result && (
                <>
                  <MarkdownPreview source={`${cleanResult}`} />
                </>
              )}

              <div className="mt-8">
                {isBlockReferencesError && (
                  <div className="flex justify-start items-center">
                    <Typography variant="textmd" weight="semibold" className="text-shade-black text-left">
                      There was an unexpected error while retrieving References
                    </Typography>
                  </div>
                )}

                {isFetchingBlockReferences && (
                  <div className="">
                    <Loading numberOfLoaders={1} className="bg-grey-200 !h-4" />
                  </div>
                )}
                {blockReferences?.data?.references.length > 0 && (
                  <div className="flex gap-4 mt-8">
                    <ReferenceCard reference={blockReferences?.data} setDisplayPdf={setDisplayPdf} displayPdf={displayPdf} />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      </div>

      <div
        className={classNames("border-2 border-error-500 transform transition-transform duration-900 ease-in-out", {
          "w-[50%] block h-screen": displayPdf,
          hidden: !displayPdf,
        })}
      >
        damilola is a guy
      </div>
    </div>
  );
}

interface IChadModalInterface {
  previous_response: string;
  onClose: () => void;
  report_id: string;
  block_id: string;
}

function Chat(props: IChadModalInterface) {
  const { previous_response, onClose, report_id, block_id } = props;

  const [query, setQuery] = useState("");
  const [question, setQuestion] = useState("");
  const [session_id, setSession_id] = useState<string | null>();
  const [result, setResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { chatQuery } = useChatQueries();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleSubmit = async () => {
    setQuestion(query);
    setIsLoading(true);
    setIsError(false);
    const data = { session_id, query, report_id, block_id };

    const customId = "custom-toast-id";

    setQuery("");
    chatQuery(data, {
      onError: (error, variables, context) => {
        setIsError(true);
        setIsLoading(false);
      },
      onSuccess: (data, variables, context) => {
        const { session_id, content } = data.data;
        setSession_id(session_id);
        setResult(content);
        setIsLoading(false);
        setIsError(false);
      },
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="h-screen w-full flex flex-col justify-between">
      <div className="border-b-[1px] border-grey-100 px-6 pt-6 pb-4">
        <div className="flex justify-end items-center">
          <div
            aria-label="edit"
            className="hover:bg-grey-100 hover:cursor-pointer flex justify-center items-center  rounded-full  h-8 w-8"
            onClick={onClose}
          >
            <IoMdClose className=" h-6 w-6" />
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full">
        <div className="px-6 py-4 w-[60%]">
          <Typography variant="textsm" className="text-left">
            {question}
          </Typography>{" "}
        </div>
        <div className="w-full">
          {isLoading && (
            <div className="flex justify-center items-start p-6">
              <div className=" h-full w-[100%]">
                <Loading numberOfLoaders={12} className="bg-grey-200 !h-4" />
              </div>
            </div>
          )}
          {isError && (
            <div className="flex justify-center items-center p-6">
              <div className=" h-full w-[100%] mt-10">
                <Typography variant="displayxs" className="text-center text-grey-500">
                  Error fetching result
                </Typography>{" "}
              </div>
            </div>
          )}
          {!isLoading && result && (
            <div className="h-full  px-6 w-full flex justify-end mt-1">
              <div className="max-w-[80%] overflow-auto max-h-[600px]">
                <Typography variant="textsm" className="text-justify">
                  {result}
                </Typography>{" "}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="relative mt-2 rounded-md p-6 ">
        <input
          className="bg-grey-75 h-14 px-3 pl-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
          placeholder="Type here..."
          value={query}
          onChange={(e) => handleChange(e)}
          onKeyDown={handleKeyPress}
        />
        <div className="absolute inset-y-0 right-8 flex items-center hover:cursor-pointer">
          <div className="h-10 w-10 rounded-full bg-shade-white flex items-center justify-center" onClick={() => handleSubmit()}>
            <img className="w-6 h-6 text-gray-500 sm:text-sm" alt="send message" src={send_icon} />
          </div>
        </div>
      </div>
    </div>
  );
}

interface Reference {
  id: number;
  data_source_id: number;
  report_block_id: number;
  type: string;
  format: string;
  content: any[];
  metadata: string;
  account_id: number | null;
  created: string;
  updated: string;
}

interface ReferenceCardProps {
  reference: any;
  displayPdf: boolean;
  setDisplayPdf: (value: boolean) => void;
}

interface DataSource {
  id: number;
  data_source_name: string;
  data_source_type: string;
  contents: Reference[];
}

function ReferenceCard({ reference, setDisplayPdf, displayPdf }: ReferenceCardProps) {
  const [openIndex, setOpenIndex] = useState(null);
  const [content, setContent] = useState<Partial<DataSource>>();

  const toggleDropdown = (index: any, refId: string) => {
    setDisplayPdf(!displayPdf);
    setOpenIndex(openIndex === index ? null : index);
    setContent(openIndex === index ? null : reference?.references.find((ref: any) => ref.id === refId));
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex items-center w-full gap-4">
        <div className="flex items-start h-full">
          <Typography variant="textsm" className="weigth-regular">
            Reference:
          </Typography>
        </div>
        <div className="flex items-end gap-4 flex-wrap">
          {reference?.references.map((ref: any, index: any) => (
            <div key={index} className="flex">
              <button
                onClick={() => toggleDropdown(index, ref.id)}
                className="flex items-center  justify-between w-full ml-1 bg-gray-100 hover:bg-gray-200 rounded-md"
              >
                <div className="flex items-center flex-grow gap-2">
                  <img
                    src={ref.data_source_type === "csv" ? csv_icon : ref.data_source_type === "xlsx" ? xlsx_icon : pdf_icon}
                    alt="pdf"
                    className="h-6 w-6"
                  />
                  <Typography variant="textsm" className="weigth-regular">
                    {ref.data_source_name}.{ref.data_source_type}
                  </Typography>
                </div>
                <span className="ml-1">{openIndex === index ? <FaChevronUp size={15} /> : <FaChevronDown size={15} />}</span>
              </button>
            </div>
          ))}
        </div>
      </div>

      {content?.contents && (
        <div className={`flex flex-col gap-4 w-full max-w-[900px] mt-4 bg-grey-75 p-4 rounded-md`}>
          {content?.contents.map((item, index) => (
            <div key={index} className="flex flex-col gap-1">
              {item.metadata && (
                <Typography variant="textsm" className="text-gray-500">
                  [Page {JSON.parse(item.metadata.replace(/^"|"$/g, "").replace(/\\/g, ""))?.page_number}]
                </Typography>
              )}
              {item.type === "pdf" && (
                <Typography variant="textsm" className="text-sm">
                  "{item.content}"
                </Typography>
              )}

              {item.type === "sql" && (
                <Typography variant="textsm" className="text-sm">
                  {format(`${item.content}`, {
                    language: "mysql",
                    tabWidth: 2,
                    keywordCase: "preserve",
                    dataTypeCase: "preserve",
                    functionCase: "preserve",
                    identifierCase: "preserve",
                    indentStyle: "standard",
                    logicalOperatorNewline: "before",
                    newlineBeforeSemicolon: true,
                    expressionWidth: 50,
                    linesBetweenQueries: 1,
                  })}
                </Typography>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
