import {
  brief_icon,
  csv_icon,
  delete_icon,
  detailed_icon,
  comprehensive_icon,
  pdf_icon,
  search_icon,
  xlsx_icon,
  paragraph_icon,
  list_icon,
  table_icon,
  chart_icon,
  pie_chart_icon,
  bar_chart_icon,
  line_chart_icon,
} from "assets/svg";
import { AddDataSourceModal, Button } from "components";
import Typography from "components/Typography";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IDataSource } from "types";
import { formikHelper, fuzzySearch } from "utils";
import * as Yup from "yup";
import { TfiDownload } from "react-icons/tfi";
import { useFetchBlock, useFetchDataSources, useGetReportDataSources, useUpdateBlock } from "services";
import Loading from "components/Loading";
import { GoPlus } from "react-icons/go";
import classNames from "classnames";
import { useParams } from "react-router";
import { Modal } from "components/Modal";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { IoMdClose } from "react-icons/io";

interface ISelectedDataSource {
  id: number;
  name: string;
  type: string;
}

export function EditBlock() {
  const CreateReportSchema = Yup.object().shape({
    title: Yup.string().min(2, "Too Short!").required("Title is Required"),
    query: Yup.string().min(2, "Too Short!").required("Query is Required"),
    result_format: Yup.string().min(2, "Too Short!").required("Required"),
    result_style: Yup.string().min(2, "Too Short!").required("Required"),
    extra_information: Yup.string().min(2, "Too Short!"),
    data_source_id: Yup.number().required("Required"),
    table_format_style_selection: Yup.boolean(),
    table_row_description: Yup.string().when("table_format_style_selection", {
      is: true,
      then: (schema) => schema.required("Row description is required"),
    }),
    table_columns: Yup.string().when("table_format_style_selection", {
      is: true,
      then: (schema) => schema.required("Columns is required"),
    }),
    chart_format_style_selection: Yup.boolean(),
    chart_type: Yup.string().when("chart_format_style_selection", {
      is: true,
      then: (schema) => schema.required("chart type is required"),
    }),
    chart_instruction: Yup.string().when("chart_format_style_selection", {
      is: true,
      then: (schema) => schema.required("Chart instruction is required"),
    }),
  });

  const param = useParams();
  const { reportId, blockId } = param;

  const { data: block, isFetching: isFetchingBlock, error: isBlockError } = useFetchBlock(reportId as string, blockId as string);

  const { data, error, isFetching } = useGetReportDataSources(reportId as string);

  useEffect(() => {
    if (block && block.data.block) {
      const datasource = sources && sources.find((data_source: IDataSource) => data_source.id === block?.data?.block?.data_source_id);

      setSelectedDataSource(datasource as ISelectedDataSource);
    }
  }, [block]);

  const [selectedDataSource, setSelectedDataSource] = useState<ISelectedDataSource | null>(null);
  const [resultFormat, setResultFormat] = useState<string>("");
  const [resultStyle, setResultStyle] = useState<string>("");
  const [chartType, setChartType] = useState<string>("");
  const [radioValue, setRadioValue] = useState<any>(undefined);
  const [dataSources, setDataSources] = useState<Array<Partial<IDataSource>>>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Partial<IDataSource>[]>();

  useEffect(() => {
    if (searchTerm.length > 0) {
      setSearchResults(fuzzySearch("name", searchTerm, sources as Partial<IDataSource>[]));
    }
  }, [searchTerm]);

  const removeDataSource = (id: number) => {
    const filterDatasource = dataSources.filter((data_source) => data_source.id !== id);

    setDataSources(filterDatasource);
  };

  const updateDataSource = (data_source: Partial<ISelectedDataSource>) => {
    setRadioValue(data_source);
    setSelectedDataSource(data_source as ISelectedDataSource);
  };

  const dataSourceId = selectedDataSource && selectedDataSource?.id;

  const displayNoDataSourcesAvailable = data && data.data.data_sources.length === 0;

  const sources = searchResults && searchResults.length > 0 ? searchResults : data && data?.data?.data_sources;

  const displayDataSources = sources && sources.length > 0 && !isFetching;

  const blockData = block && block.data.block;

  const { updateBlock } = useUpdateBlock();

  useEffect(() => {
    if (blockData) {
      setResultFormat(blockData?.result_format as string);
      setResultStyle(blockData?.result_style as string);
      setChartType(blockData?.chart_type as string);
    }
  }, [blockData]);

  const customId = "custom-toast-id";

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    const data = { ...values, data_source_id: dataSourceId, report_id: reportId as string, block_id: blockId };

    updateBlock(data, {
      onError: (error, variables, context) => {
        toast.error("Error updating block", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["blocks"] });
        setSelectedDataSource(null);
        toast.success("Block updated Successfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      },
    });
  };

  const [dataSourcesmodalOpen, setDataSourcesModalOpen] = useState<boolean>(false);

  return (
    <div className="pb-[69px] max-w-[1400px]">
      <div className="flex gap-3 items-center hover:cursor-pointer mb-8" onClick={() => window.history.back()}>
        <FaArrowLeftLong />{" "}
        <Typography variant="body2" className="text-grey-900">
          Go Back
        </Typography>
      </div>
      <div className="mb-6">
        <Typography variant="displayxs" className="text-shade-black text-left !font-bold">
          Edit Block
        </Typography>
      </div>

      <div className="flex gap-6">
        <div className="w-[682px]">
          {isBlockError && (
            <div className="flex justify-center items-center w-full h-full">
              {" "}
              <Typography variant="displaylg" className="text-grey-600 text-center">
                {" "}
                There was an unexpected error while retrieving block
              </Typography>
            </div>
          )}
          {isFetchingBlock && (
            <div className="h-full w-[408px] mt-20 mx-auto">
              <Loading numberOfLoaders={6} className="bg-grey-200 !h-16" />
            </div>
          )}
          {blockData && (
            <Formik
              initialValues={{
                title: blockData?.title || "",
                query: blockData?.query || "",
                result_format: blockData?.result_format || "",
                result_style: blockData?.result_style || "",
                extra_information: blockData?.extra_information || "",
                table_row_description: blockData?.table_row_description || "",
                table_columns: blockData?.table_columns || "",
                data_source_id: blockData?.data_source_id || dataSourceId || 0,
                table_format_style_selection: false || blockData?.result_format === "table",
                chart_format_style_selection: false || blockData?.result_format === "chart",
                chart_type: blockData?.chart_type || "",
                chart_instruction: blockData?.chart_instruction || "",
              }}
              validationSchema={CreateReportSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <>
                  <form className="" onSubmit={formik.handleSubmit} noValidate>
                    {dataSourcesmodalOpen && (
                      <Modal
                        onClose={() => setDataSourcesModalOpen(false)}
                        open={dataSourcesmodalOpen}
                        variant="full"
                        children={
                          <DataSourcesModal
                            value={radioValue}
                            data={data?.data.data_sources as Partial<IDataSource>[]}
                            setRadioValue={updateDataSource}
                            onClose={setDataSourcesModalOpen}
                          />
                        }
                      />
                    )}
                    <div className="flex flex-col w-full gap-8 border-[1.2px] py-10 px-5 border-grey-200 rounded-md">
                      <div className="flex flex-col gap-2">
                        <Typography variant="body2" className="text-shade-black text-left !font-semibold">
                          Block Title
                        </Typography>
                        <input
                          className="h-14 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                          placeholder="Block Title"
                          {...formik.getFieldProps("title")}
                          {...formikHelper(formik, "title")}
                          onChange={(event) => {
                            formik.setFieldValue("title", event.target.value);
                          }}
                        />
                      </div>

                      <div className="flex flex-col gap-2">
                        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
                          Query
                        </Typography>
                        <textarea
                          className="h-28 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                          placeholder="Condense this report"
                          {...formik.getFieldProps("query")}
                          {...formikHelper(formik, "query")}
                          onChange={(event) => {
                            formik.setFieldValue("query", event.target.value);
                          }}
                        />
                      </div>

                      <>
                        <div className="flex flex-col gap-2">
                          <Typography variant="body2" className="!font-semibold text-shade-black text-left">
                            Result Format
                          </Typography>

                          <div className="flex gap-4">
                            <div
                              className={classNames(
                                "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                                {
                                  "bg-primary-50": resultFormat === "paragraph",
                                }
                              )}
                              onClick={() => {
                                setResultFormat("paragraph");
                                formik.setFieldValue("result_format", "paragraph");
                                formik.setFieldValue("data_source_id", dataSourceId);
                                if (resultFormat === "chart") {
                                  setSelectedDataSource(null);
                                }
                              }}
                            >
                              <img src={paragraph_icon} alt="paragraph" className="w-[20px] h-[20px]" />
                              <Typography variant="textsm">Paragraph</Typography>
                            </div>
                            <div
                              className={classNames(
                                "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                                {
                                  "bg-primary-50": resultFormat === "list",
                                }
                              )}
                              onClick={() => {
                                setResultFormat("list");
                                formik.setFieldValue("result_format", "list");
                                formik.setFieldValue("data_source_id", dataSourceId);
                                if (resultFormat === "chart") {
                                  setSelectedDataSource(null);
                                }
                              }}
                            >
                              <img src={list_icon} alt="list" className="w-[20px] h-[20px]" />
                              <Typography variant="textsm">List</Typography>
                            </div>
                            <div
                              className={classNames(
                                "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                                {
                                  "bg-primary-50": resultFormat === "table",
                                }
                              )}
                              onClick={() => {
                                setResultFormat("table");
                                formik.setFieldValue("result_format", "table");
                                formik.setFieldValue("data_source_id", dataSourceId);
                                if (resultFormat === "chart") {
                                  setSelectedDataSource(null);
                                }
                              }}
                            >
                              <img src={table_icon} alt="table" className="w-[20px] h-[20px]" />
                              <Typography variant="textsm">Table</Typography>
                            </div>

                            <div
                              className={classNames(
                                "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                                {
                                  "bg-primary-50": resultFormat === "chart",
                                }
                              )}
                              onClick={() => {
                                setResultFormat("chart");
                                formik.setFieldValue("result_format", "chart");
                                formik.setFieldValue("data_source_id", dataSourceId);
                                if (resultFormat !== "chart") {
                                  setSelectedDataSource(null);
                                }
                              }}
                            >
                              <img src={chart_icon} alt="chart" className="w-[20px] h-[20px]" />
                              <Typography variant="textsm">Chart</Typography>
                            </div>
                          </div>
                        </div>
                        {resultFormat === "table" && (
                          <>
                            <div className="flex flex-col gap-2">
                              <Typography variant="body2" className="text-shade-black text-left !font-semibold">
                                Table Prompt
                              </Typography>
                              <Typography variant="textsm" className="text-shade-black text-left !font-meduim">
                                Row description
                              </Typography>
                              <input
                                className="h-14 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                placeholder="Row Description"
                                {...formik.getFieldProps("table_row_description")}
                                {...formikHelper(formik, "table_row_description")}
                                onChange={(event) => {
                                  formik.setFieldValue("table_row_description", event.target.value);
                                }}
                              />
                            </div>

                            <div className="flex flex-col gap-2">
                              <Typography variant="textsm" className="text-shade-black text-left !font-meduim">
                                Columns
                              </Typography>
                              <input
                                className="h-14 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                placeholder="Columns"
                                {...formik.getFieldProps("table_columns")}
                                {...formikHelper(formik, "table_columns")}
                                onChange={(event) => {
                                  formik.setFieldValue("table_columns", event.target.value);
                                }}
                              />
                            </div>
                          </>
                        )}

                        {resultFormat === "chart" && (
                          <>
                            <div className="flex flex-col gap-2">
                              <div className="flex flex-col gap-2">
                                <Typography variant="body2" className="!font-semibold text-shade-black text-left">
                                  Chart Type
                                </Typography>

                                <div className="flex gap-4">
                                  <div
                                    className={classNames(
                                      "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                                      {
                                        "bg-primary-50": chartType === "pie_chart",
                                      }
                                    )}
                                    onClick={() => {
                                      setChartType("pie_chart");
                                      formik.setFieldValue("chart_type", "pie_chart");
                                    }}
                                  >
                                    <img src={pie_chart_icon} alt="brief" className="w-[20px] h-[20px]" />
                                    <Typography variant="textsm">Pie Chart</Typography>
                                  </div>
                                  <div
                                    className={classNames(
                                      "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                                      {
                                        "bg-primary-50": chartType === "bar_chart",
                                      }
                                    )}
                                    onClick={() => {
                                      setChartType("bar_chart");
                                      formik.setFieldValue("chart_type", "bar_chart");
                                    }}
                                  >
                                    <img src={bar_chart_icon} alt="comprehensive" className="w-[20px] h-[20px]" />
                                    <Typography variant="textsm">Bar Chart</Typography>
                                  </div>
                                  <div
                                    className={classNames(
                                      "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                                      {
                                        "bg-primary-50": chartType === "line_chart",
                                      }
                                    )}
                                    onClick={() => {
                                      setChartType("line_chart");
                                      formik.setFieldValue("chart_type", "line_chart");
                                    }}
                                  >
                                    <img src={line_chart_icon} alt="detailed" className="w-[20px] h-[20px]" />
                                    <Typography variant="textsm">Line Chart</Typography>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col gap-2">
                              <Typography variant="body2" className="!font-semibold text-shade-black text-left">
                                Chart Instruction
                              </Typography>
                              <input
                                className="h-14 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                placeholder="Chart instruction"
                                {...formik.getFieldProps("chart_instruction")}
                                {...formikHelper(formik, "chart_instruction")}
                                onChange={(event) => {
                                  formik.setFieldValue("chart_instruction", event.target.value);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </>

                      <div className="flex flex-col gap-2">
                        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
                          Result Style
                        </Typography>

                        <div className="flex gap-4">
                          <div
                            className={classNames(
                              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                              {
                                "bg-primary-50": resultStyle === "brief",
                              }
                            )}
                            onClick={() => {
                              setResultStyle("brief");
                              formik.setFieldValue("result_style", "brief");
                              formik.setFieldValue("data_source_id", dataSourceId);
                            }}
                          >
                            <img src={brief_icon} alt="brief" className="w-[20px] h-[20px]" />
                            <Typography variant="textsm">Brief</Typography>
                          </div>
                          <div
                            className={classNames(
                              "w-[120px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                              {
                                "bg-primary-50": resultStyle === "comprehensive",
                              }
                            )}
                            onClick={() => {
                              setResultStyle("comprehensive");
                              formik.setFieldValue("result_style", "comprehensive");
                              formik.setFieldValue("data_source_id", dataSourceId);
                            }}
                          >
                            <img src={comprehensive_icon} alt="comprehensive" className="w-[20px] h-[20px]" />
                            <Typography variant="textsm">Comprehensive</Typography>
                          </div>
                          <div
                            className={classNames(
                              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                              {
                                "bg-primary-50": resultStyle === "detailed",
                              }
                            )}
                            onClick={() => {
                              setResultStyle("detailed");
                              formik.setFieldValue("result_style", "detailed");
                              formik.setFieldValue("data_source_id", dataSourceId);
                            }}
                          >
                            <img src={detailed_icon} alt="detailed" className="w-[20px] h-[20px]" />
                            <Typography variant="textsm">Detailed</Typography>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-start flex-col gap-4">
                        <div className="flex items-center gap-2">
                          <div
                            className={classNames("text-primary-700 flex gap-1 items-center hover:cursor-pointer", {
                              "opacity-50 pointer-events-none": !resultFormat,
                            })}
                            onClick={() => setDataSourcesModalOpen(true)}
                          >
                            <GoPlus className="!font-bold h-6 w-6 mb-1" />
                            <div className="flex items-center gap-2">
                              <Typography variant="body2" className="!font-semibold text-primary-700">
                                Add Sources
                              </Typography>
                            </div>
                          </div>

                          <Typography variant="textsm" className="!font-semibold text-grey-500">
                            (Select result format first)
                          </Typography>
                        </div>
                        <div
                          className={classNames("h-[60px] bg-grey-50 px-2 flex items-center ", {
                            "hover:bg-grey-100": selectedDataSource,
                          })}
                        >
                          {selectedDataSource && (
                            <div className="flex gap-4 items-center justify-between">
                              <div className="flex gap-3 items-center">
                                <img
                                  src={selectedDataSource.type === "csv" ? csv_icon : selectedDataSource.type === "pdf" ? pdf_icon : xlsx_icon}
                                  alt="data source"
                                  className="h-10 w-10"
                                />
                                <Typography variant="textsm" weight="bold" className="font-semibold">
                                  {selectedDataSource.name}
                                </Typography>
                              </div>
                              <img src={delete_icon} alt="delete" onClick={() => setSelectedDataSource(null)} className="cursor-pointer" />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 mt-6">
                        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
                          Extra Information (Optional)
                        </Typography>
                        <textarea
                          className="h-28 mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                          placeholder="Extra information"
                          {...formik.getFieldProps("extra_information")}
                          {...formikHelper(formik, "extra_information")}
                          onChange={(event) => {
                            formik.setFieldValue("extra_information", event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-10">
                      <Button
                        onClick={async () => {
                          const validation = await formik.validateForm();
                          if (Object.keys(validation).length) return;
                        }}
                        type="submit"
                        title="Save"
                        disabled={formik.isSubmitting || !formik.isValid || !dataSourceId}
                        fullwidth
                      />
                    </div>
                  </form>
                </>
              )}
            </Formik>
          )}
        </div>
        <div className="w-full max-w-[482px] py-10  border-[1.2px] border-grey-200 rounded-md max-h-[600px] overflow-y-auto">
          <div className="flex justify-between items-center px-5">
            <Typography variant="textxl" className="!font-semibold text-shade-black text-left">
              Included Data Sources
            </Typography>

            <div className="" onClick={() => setModalOpen(true)}>
              <Button icon={<TfiDownload />} title="Upload Another file" type="submit" />
            </div>
          </div>

          <div className="mt-4 px-5">
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <img className="text-gray-500 sm:text-sm" alt="search" src={search_icon} />
              </div>

              <input
                className="h-12 px-3 pl-10 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Search data source"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="px-5">
            {isFetching && (
              <div className="mt-4">
                {" "}
                <Loading numberOfLoaders={4} height={10} className="bg-grey-300" />
              </div>
            )}
            {displayNoDataSourcesAvailable && (
              <div className="flex justify-center items-center">
                <Typography variant="displayxs" weight="semibold">
                  No data source available
                </Typography>{" "}
              </div>
            )}
          </div>
          <div className="mt-4 flex gap-4 flex-col px-5">
            {displayDataSources &&
              sources?.map((dataSource: Partial<IDataSource>) => (
                <div key={dataSource.id} className="">
                  <div
                    className="flex gap-4 items-center  hover:cursor-pointer hover:bg-grey-100 px-[2px] py-2"
                    onClick={() =>
                      setSelectedDataSource({ id: dataSource?.id as number, name: dataSource?.name as string, type: dataSource?.type as string })
                    }
                  >
                    <img
                      src={dataSource.type === "csv" ? csv_icon : dataSource.type === "pdf" ? pdf_icon : xlsx_icon}
                      alt="pdf"
                      className="h-10 w-10"
                    />
                    <Typography variant="body2" className="!font-semibold">
                      {dataSource.name}
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
          {modalOpen && (
            <Modal
              onClose={() => setModalOpen(false)}
              open={modalOpen}
              variant="full"
              children={<AddDataSourceModal onClose={() => setModalOpen(false)} report_id={reportId} />}
            />
          )}
        </div>
      </div>
    </div>
  );
}

interface IDataSourceModal {
  data: Partial<IDataSource>[];
  setRadioValue: (e: any) => void;
  value: any;
  onClose: (close: boolean) => void;
}

function DataSourcesModal(props: IDataSourceModal) {
  const { data, setRadioValue, onClose, value } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Partial<IDataSource>[]>();

  useEffect(() => {
    if (searchTerm.length > 0) {
      setSearchResults(fuzzySearch("name", searchTerm, data));
    }
  }, [searchTerm]);

  const source = searchResults && searchResults?.length > 0 ? searchResults : data;

  const displayDataSource = source?.length > 0;

  const displayNoDataSource = source?.length === 0;

  return (
    <div className="flex bg-[#FFF] flex-col rounded-md w-auto">
      <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
        <Typography variant="textxl" className="font-semibold">
          Choose File Add Source
        </Typography>

        <div onClick={() => onClose(false)} className="hover:cursor-pointer h-6 w-6">
          <IoMdClose className="w-full h-full" />
        </div>
      </div>
      {displayNoDataSource && (
        <div className="flex justify-center items-center h-56 px-8">
          <Typography variant="displaysm" className="text-grey-500">
            No Data Source Found
          </Typography>{" "}
        </div>
      )}
      {displayDataSource && (
        <>
          <div className="mt-3">
            <div className="relative rounded-md px-6 pt-2">
              <div className="pointer-events-none absolute inset-y-0 left-6 flex items-center pl-3">
                <img className="text-grey-500 sm:text-sm" alt="search" src={search_icon} />
              </div>

              <input
                className="h-12 px-3 pl-10 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Search data source"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="px-6 pb-8 pt-4 flex gap-4 flex-col overflow-auto w-[470px]  h-[350px]">
            {source?.map((data_source: Partial<IDataSource>, index) => (
              <div className="flex items-center gap-4 hover:cursor-pointer" key={data_source.id} onClick={() => setRadioValue(data_source)}>
                <input
                  type="radio"
                  id="radioColor"
                  name="fileType"
                  value={value}
                  checked={Number(value?.id) === data_source.id}
                  onChange={() => {
                    setRadioValue(data_source);
                  }}
                  className="w-6 h-6"
                />
                <img
                  src={data_source.type === "csv" ? csv_icon : data_source.type === "pdf" ? pdf_icon : xlsx_icon}
                  alt="pdf"
                  className="h-10 w-10"
                />
                <Typography variant="body2" className="font-medium">
                  {data_source.name}
                </Typography>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="flex justify-end items-center px-6 mb-6 mt-6">
        <Button type="button" title="Continue" onClick={() => onClose(false)} />
      </div>{" "}
    </div>
  );
}
