import { AddDataSourceModal, Button } from "components";
import DataSourceComponent from "components/DataSourceComponent";
import Header from "components/Header";
import Loading from "components/Loading";
import Typography from "components/Typography";
import { MdDeleteOutline, MdPlusOne } from "react-icons/md";
import { useFetchDataSources } from "services";
import { MdAdd } from "react-icons/md";
import { useState } from "react";
import { Modal } from "components/Modal";
import { IDataSource } from 'types';
import ReactPaginate from 'react-paginate';

export function DataSources() {
  const headerProps = {
    title: "Data Sources",
    buttonTitle: "New Source",
  };

  const { data, isFetching, error } = useFetchDataSources();

  const displayNoDataSourcesAvailable = data && data.data.data_sources.length === 0 && !isFetching;

  const dataSources = data?.data?.data_sources as Partial<IDataSource>[];

  const displayDataSources = dataSources && dataSources.length > 0 && !isFetching;

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = dataSources?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataSources?.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % dataSources?.length;
    setItemOffset(newOffset);
  };


  return (
    <div className="flex flex-col items-center w-[858px] pb-10">
      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          open={modalOpen}
          variant="full"
          children={<AddDataSourceModal onClose={() => setModalOpen(false)} />}
        />
      )}
      <div>
        {error && (
          <div className="mt-[60px] flex justify-center items-center">
            <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
              There was an unexpected error while retrieving data sources
            </Typography>
          </div>
        )}
        {isFetching && (
          <div className="h-full w-[758px] mt-20">
            <Loading numberOfLoaders={6} className="bg-grey-200 !h-16" />
          </div>
        )}
        {displayNoDataSourcesAvailable && (
          <div className="flex justify-center items-center h-full w-full">
            <div className="flex flex-col items-centergap-8">
              <Typography variant="displaymd" className="text-grey-500">
                No Data Sources Available
              </Typography>
              <div className="flex justify-end items-center mb-8">
                <Button icon={<MdAdd />} title={headerProps.buttonTitle} onClick={() => setModalOpen(true)} />
              </div>
            </div>
          </div>
        )}
        {displayDataSources && (
          <>
            <Header title={headerProps.title} />

            <div className="flex justify-end items-center mb-8">
              <Button icon={<MdAdd />} title={headerProps.buttonTitle} onClick={() => setModalOpen(true)} />
            </div>
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-5">
                {displayDataSources &&
                  currentItems?.map((dataSource) => (
                    <div key={dataSource.id}>
                      <DataSourceComponent
                        name={dataSource.name as string}
                        id={dataSource.id as number}
                        updated={dataSource.updated as string}
                        type={dataSource.type as string}
                      />
                    </div>
                  ))}
                <div className="mt-2">
                  <ReactPaginate
                    marginPagesDisplayed={2}
                    breakLabel="..."
                    nextLabel={<Button title="Next" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<Button title="Previous" />}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    activeClassName="active-page"
                    breakLinkClassName="page-link"
                    pageClassName="page-item"
                    breakClassName="page-item"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
