export interface IDataSource {
  created: string;
  id: number;
  metadata?: string | null;
  name: string | null;
  report_id?: string | null;
  type?: string | null;
  type_class?: string | null;
  updated: string | null;
}

export interface IDataSourcesAPIResponse {
  data: IDataSourcesArray;
}

export interface IDataSourcesArray {
  data_sources: Partial<IDataSource>[];
}

export enum FileTypes {
  CSV = "csv",
  XLSX = "xlsx",
  PDF = "pdf",
  DOCX = "docx",
}

export enum ModelInView {
  DATATYPE = "datatype",
  UPLOAD = "upload",
}

export enum ISeparatorEnum {
  COMMA = "comma",
  SEMICOLON = "semicolon",
  TAB = "tab",
  PIPE = "pipe",
}

export enum ISeparatorEnumValue {
  COMMA = ",",
  SEMICOLON = ";",
  TAB = "\t",
  PIPE = "|", 
}