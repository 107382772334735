import { ball_icon } from "assets/svg";

export function LoadingReport() {
  return (
    <div className="animate-pulse">
      <div className="flex-1">
        <div className={" h-5 rounded-lg bg-gradient-to-tl from-grey-200 to-grey-400"} />
        <div className="flex justify-between items-center mt-6">
          <div className={"w-[20%]  h-5 rounded-lg bg-gradient-to-tl from-grey-200 to-grey-400"} />

          <div className="flex gap-2">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="flex justify-center items-center h-12 w-12 bg-grey-75">
                <img src={ball_icon} alt="loading" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex-1 sspace-y-[6px]  mt-10">
        <div className="flex-1 space-y-[6px] mt-9">
          {[...Array(6)].map((_, index) => (
            <div className={" h-10 rounded-lg bg-gradient-to-tl from-grey-200 to-grey-400"} key={index} />
          ))}
        </div>

        <div className="mt-8">
          <div className="flex-1 space-y-[6px] mt-9">
            {[...Array(6)].map((_, index) => (
              <div className={" h-10 rounded-lg bg-gradient-to-tl from-grey-200 to-grey-400"} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
