import classNames from "classnames";
import React from "react";
import Typography from "./Typography";

interface IButton {
  title: string;
  icon?: React.ReactNode | string;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  color?: "info" | "error" | "primary";
  size?: "small" | "meduim" | "large";
  variant?: "filled" | "outlined" | "text";
  weigth?: "regular" | "medium" | "semibold" | "bold";
  fullwidth?: boolean;
  className?: string;
  background?: string;
  colorText?: string;
}

export function Button(props: IButton) {
  const {
    className,
    title,
    icon,
    onClick,
    disabled = false,
    type = "button",
    color = "primary",
    variant,
    weigth = "semibold",
    fullwidth = false,
    background,
    colorText
  } = props;


  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={classNames(`${className} h-10 rounded-[4px] py-2 px-4 flex items-center gap-2`, {
        "bg-primary-700 hover:bg-primary-800": color === "primary",
        "bg-error-500 hover:bg-error-600": color === "error",
        "bg-white border-2 border-grey-200": color === "info",
        "opacity-50": disabled,
        "w-full justify-center": fullwidth,
        "max-w-[500px] w-auto": !fullwidth,
      })}
    >
      {icon && (
        <span
          className={classNames({
            "text-grey-500": color === "info",
            "text-shade-white": color !== "info",
          })}
        >
          {icon}
        </span>
      )}
      <Typography
        variant="body2"
        weight={weigth}
        className={classNames({
          "text-grey-500": color === "info",
          "text-shade-white": color !== "info",
        })}
      >
        {title}
      </Typography>
    </button>
  );
}

// TODO
// Decide button icon prop component/svg
