import { Button } from "./Button";
import Typography from "./Typography";

export function ErrorFallback() {
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div>
        <Typography variant="display3xl">An Error Occured</Typography>
        <div className="mt-6">
          <Button title="Refresh" onClick={() => window.location.reload()} />
        </div>
      </div>
    </div>
  );
}
