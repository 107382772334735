import { MdAdd, MdDashboard } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router";
import { Context_logo_dark, Context_logo_light } from "assets/images";
import Typography from "./Typography";
import { Modal } from "./Modal";
import { CreateReport } from "pages/new-reports/create-report";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { dashboard_icon, dashboard_square_01, datasources_sidebar_icon, reports_sidebar_icon } from "assets/svg";

export function SideBar() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [currentHover, setCurrentHover] = useState<"reports" | "data-sources">();

  const { pathname } = useLocation();

  const activeTab = pathname.split("/")[1] as "reports" | "data-sources";

  return (
    <div className="sticky h-min-screen w-full flex flex-col items-center pt-[43.11px]">
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)} open={modalOpen} variant="full" children={<CreateReport onClose={() => setModalOpen(false)} />} />
      )}
      <div className="mb-[47.32px]">
        <img src={Context_logo_light} alt="context data" />
      </div>
      <div className="flex items-center gap-1 cursor-pointer p-3 w-full bg-[#ededef0f] mb-5 rounded " onClick={() => setModalOpen(true)}>
        <MdAdd className="text-shade-white regular w-[22px] h-[22px]" />{" "}
        <Typography variant="textsm" className="text-shade-white">
          New Report
        </Typography>
      </div>

      <div
        className={classNames("text-left cursor-pointer  p-3 w-full flex justify-center gap-2", {
          "text-shade-white": activeTab === "reports",
          "hover:text-shade-white text-grey-500": activeTab !== "reports",
        })}
        onClick={() => navigate("reports")}
        onMouseEnter={() => setCurrentHover("reports")}
        onMouseLeave={() => setCurrentHover(activeTab)}
      >
        <img
          src={reports_sidebar_icon}
          alt="reports"
          className={classNames("w-5 h-5", {
            "!bg-shade-white": activeTab === "reports" || currentHover === "reports",
            "hover:bg-shade-white bg-grey-500": activeTab !== "reports" || currentHover === "reports",
          })}
        />
        <Typography variant="textsm" className={classNames("text-left cursor-pointer w-full")}>
          Reports
        </Typography>
      </div>
      <div
        className={classNames("text-left cursor-pointer p-2 w-full flex  gap-1 items-start", {
          "text-shade-white": activeTab === "data-sources",
          "hover:text-shade-white text-grey-500": activeTab !== "data-sources",
        })}
        onClick={() => navigate("data-sources")}
        onMouseEnter={() => setCurrentHover("data-sources")}
        onMouseLeave={() => setCurrentHover(activeTab)}
      >
        {" "}
        <div>
          <MdDashboard
            className={classNames("w-[28px] h-[28px]", {
              "!text-shade-white": activeTab === "data-sources" || currentHover === "data-sources",
              "hover:text-shade-white text-grey-500": activeTab !== "data-sources" || currentHover === "data-sources",
            })}
          />
        </div>
        <div className="flex items-center h-full pt-1">
          <Typography variant="textsm" className={classNames("text-left cursor-pointer")}>
            Data sources
          </Typography>
        </div>
      </div>
    </div>
  );
}
