import { IDataSource } from "./data-source.type";

export interface ICreateReport {
  title: string;
  data_sources: Partial<IDataSource>[];
}

export interface IReportsAPIResponse {
  data: IReportsArray;
}

export interface IReportsArray {
  reports: Partial<IReport>[];
}

export interface IReport {
  created: string;
  id: number;
  title: string;
  updated: string;
}

export enum IResultFormat {
  PARAGRAPH = "paragraph",
  LIST = "list",
  TABLE = "table",
  CHART = "chart",
}

export enum ResultStyle {
  BRIEF = "brief",
  INCLUSIVE = "inclusive",
  DETAILED = "detailed",
}
