import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdAdd, MdClose, MdSettings, MdTimeline, MdUpload } from "react-icons/md";
import DataSourcesCard, { DataSourceGridItem } from "../components/DataSourceCard";
import { useNavigate, useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { createNewBlock, createNewReport, getAllDataSources } from "../services/reports.service";
import { PDFIcon } from "../components/Icons";
//const BACKEND = process.env['REACT_APP_BACKEND_URL'] as string

export function NewReportPage() {
  const { report_id } = useParams();
  const toast = useToast();

  const [dataSources, setDataSources] = useState<Array<any>>([]);
  const [radioValue, setRadioValue] = useState<string | undefined>(undefined);
  const [addedDataSources, setAddedDataSources] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const title = useRef<string>("");

  useEffect(() => {
    getAllDataSources().then((response) => {
      setDataSources(response.data["data"]["data_sources"]);
    });
  }, []);

  return (
    <Flex height={"100vh"} width={"100%"} flexDirection={"row"}>
      <Flex direction={"column"} overflowY={"auto"} alignItems={"center"} paddingTop={"50px"} width={"70%"} height={"100%"}>
        <HStack width={"400px"}>
          <Text>Create New Report </Text>
          <Spacer />
        </HStack>

        <Box width={"400px"} marginTop={"30px"}>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input onChange={(e) => (title.current = e.target.value)}></Input>
          </FormControl>
          <Box marginTop={"30px"}>
            <HStack width={"400px"}>
              <Text>Included Data Sources</Text>
              <Spacer />
            </HStack>

            <Divider />
            <Box marginTop={"10px"} backgroundColor={"whitesmoke"} minHeight={"100px"} maxHeight={"200px"} overflowY={"auto"} marginX={"auto"}>
              {!Object.keys(addedDataSources) ? (
                <>
                  <Box height={"100%"} width={"100%"} alignContent={"center"} justifyItems={"center"}>
                    <Text opacity={"0.3"} mx={"auto"} my={"10%"} textAlign={"center"} height={"100%"}>
                      No data source added to this report
                    </Text>
                  </Box>
                </>
              ) : (
                Object.keys(addedDataSources).map((value: any, index) => (
                  <DataSourceGridItem
                    soureType={addedDataSources[value]["type"]}
                    setAddedDataSources={setAddedDataSources}
                    data={addedDataSources[value]}
                  />
                ))
              )}
            </Box>
          </Box>
        </Box>
        <HStack width={"400px"} marginTop={"30px"}>
          <Spacer />
          <Button
            backgroundColor="#a080ff"
            width={"100px"}
            color={"white"}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              createNewReport(title.current, addedDataSources).then((result) => {
                toast({
                  title: "Created Successfully",
                  status: "success",
                  duration: 2000,
                  position: "top",
                });
                setIsLoading(false);
              });
            }}
          >
            {" "}
            <HStack>
              <Text>Create</Text>
            </HStack>{" "}
          </Button>
        </HStack>
      </Flex>

      <DataSourcesPane setAddedDataSources={setAddedDataSources} />
    </Flex>
  );
}

function DataSourcesPane(props: { setAddedDataSources: Function }) {
  const [dataSources, setDataSources] = useState<Array<any>>([]);

  useEffect(() => {
    getAllDataSources().then((response) => {
      setDataSources(response.data["data"]["data_sources"]);
    });
  }, []);

  return (
    <Flex
      width={"30%"}
      height={"100vh"}
      backgroundColor={"whitesmoke"}
      flexDirection={"column"}
      paddingY={"30px"}
      paddingX={"30px"}
      overflowY={"auto"}
    >
      <Text fontWeight={"600"} textAlign={"center"}>
        Include Data Sources
      </Text>
      <Box textAlign={"center"}>
        <Text fontSize={"12px"}>Click Data Source to Include </Text>
      </Box>
      <Input marginTop={"20px"} placeholder="Search" />

      <VStack marginTop={"15px"}>
        {dataSources.map((value, index) => (
          <DataSourceItem key={index} data={value} soureType={value.type} setAddedDataSources={props.setAddedDataSources} />
        ))}
      </VStack>
    </Flex>
  );
}

export function DataSourceItem(props: { soureType: "pdf" | null; data: any; setAddedDataSources: Function }) {
  return (
    <Box
      borderRadius={0}
      paddingY={"4px"}
      width={{ base: "100%" }}
      cursor={"pointer"}
      onClick={() =>
        props.setAddedDataSources((prev: any) => {
          const object: any = {};
          object[props.data["id"]] = props.data;
          return { ...prev, ...object };
        })
      }
      _hover={{
        backgroundColor: "white",
      }}
    >
      <Tooltip hasArrow label="" bg="gray.300" color="black">
        <HStack alignItems={"center"} width={"100%"}>
          {props.soureType === "pdf" ? <PDFIcon size="35px" /> : <></>}
          <VStack>
            <Text fontSize={"12px"}>{props.data.name}</Text>
          </VStack>
        </HStack>
      </Tooltip>
    </Box>
  );
}
