import React, { createContext, useState } from "react";

export const AppContext = createContext({
  isPdfOpen: false,
  togglePdfModal: () => {},
});

interface IAppProviderProps {
  children: React.ReactNode;
}

function AppProvider(props: IAppProviderProps) {
  const { children } = props;
  const [isPdfOpen, setIsPdfOpen] = useState(false);

    const togglePdfModal = () => {
      console.log(isPdfOpen, "dvdndn");
      
    setIsPdfOpen(!isPdfOpen);
  };

  return <AppContext.Provider value={{ isPdfOpen, togglePdfModal }}>{children}</AppContext.Provider>;
}

export default AppProvider;
