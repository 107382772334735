import App from 'App';
import MainLayout from 'layouts/MainLayout';
import { CreateBlock } from 'pages/block/create-block';
import { EditBlock } from 'pages/block/edit-block';
import { DataSources } from "pages/data-sources";
import { Reports } from 'pages/new-reports';
import { NotFound } from 'pages/not-found';
import { Report } from 'pages/report';
import { createBrowserRouter } from 'react-router-dom';
import { RouteNamesEnum } from "types";


export const APP_ROUTES = [
  {
    path: RouteNamesEnum.REPORTS,
    element: <Reports />,
  },
  {
    path: RouteNamesEnum.DATASOURCES,
    element: <DataSources />,
  },
  {
    path: `${RouteNamesEnum.REPORTS}/:id`,
    element: <Report />,
  },
  {
    path: `${RouteNamesEnum.BLOCKS}/:id/create`,
    element: <CreateBlock />,
  },
  {
    path: `${RouteNamesEnum.BLOCKS}/:reportId/edit/:blockId`,
    element: <EditBlock />,
  }
];

export const PUBLIC_ROUTES = [
  {
    path: `*`,
    element: <NotFound />,
  },
];

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <MainLayout />,
        children: APP_ROUTES,
      },
      ...PUBLIC_ROUTES
    ],
  },
]);