import {
  brief_icon,
  csv_icon,
  delete_icon,
  detailed_icon,
  comprehensive_icon,
  pdf_icon,
  red_trash_icon,
  search_icon,
  xlsx_icon,
  paragraph_icon,
  list_icon,
  table_icon,
  chart_icon,
  pie_chart_icon,
  line_chart_icon,
  bar_chart_icon,
} from "assets/svg";
import { AddDataSourceModal, Button } from "components";
import Typography from "components/Typography";
import { useEffect, useMemo, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { BlockData, ICreateBlock, IDataSource } from "types";
import * as Yup from "yup";
import { TfiDownload } from "react-icons/tfi";
import { useCreateBlock, useFetchDataSources, useGetReportDataSources } from "services";
import Loading from "components/Loading";
import { GoPlus } from "react-icons/go";
import classNames from "classnames";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { Modal } from "components/Modal";
import { IoMdClose } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { fuzzySearch, validateBlockData } from "utils";

interface ISelectedDataSource {
  id: number;
  name: string;
  type: string;
}

interface FormValues {
  blocks: Partial<ICreateBlock>[];
}

export function CreateBlock() {
  const param = useParams();

  const { id } = param;

  const { data, error, isFetching } = useGetReportDataSources(id as string);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const [blocks, setBlocks] = useState<Partial<ICreateBlock>[]>([
    {
      id: crypto.randomUUID(),
      query: "",
      result_format: "",
      result_style: "",
      title: "",
      extra_information: "",
      table_row_description: "",
      table_columns: "",
      data_source_id: 0,
    },
  ]);

  const key = crypto.randomUUID();
  const object: any = {};
  object[key] = {
    query: "",
    result_format: "",
    result_style: "",
    title: "",
    extra_information: "",
    table_row_description: "",
    table_columns: "",
    data_source_id: 0,
    chart_type: "",
    chart_instruction: "",
  } as BlockData;

  const [addedBlocks, setAddedBlocks] = useState<any>({ ...object });

  //const [addedBlocks, setAddedBlocks] = useState<any>({});

  const addBlocks = () => {
    const key = crypto.randomUUID();
    const object: any = {};
    object[key] = {
      query: "",
      result_format: "",
      result_style: "",
      title: "",
      extra_information: "",
      table_row_description: "",
      table_columns: "",
      data_source_id: 0,
      chart_type: "",
      chart_instruction: "",
    } as BlockData;
    setAddedBlocks({ ...addedBlocks, ...object });
  };

  const removeBlockComponent = (key: string) => {
    delete addedBlocks[key];
    setAddedBlocks({ ...addedBlocks });
  };

  const setBlockProperty = (key: string, propertyName: string, value: string) => {
    addedBlocks[key][propertyName] = value;
    setAddedBlocks({ ...addedBlocks });
  };

  const getBlockProperty = (key: string, propertyName: string) => {
    return addedBlocks[key][propertyName];
  };

  const { createBlock } = useCreateBlock();

  const customId = "custom-toast-id";

  useEffect(() => {
    setIsValidated(validateBlockData(addedBlocks));
  }, [addedBlocks]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const data = { blocks_data: addedBlocks, report_id: id as string };

    createBlock(data, {
      onError: (error, variables, context) => {
        toast.error("Error creating block", {
          position: "top-center",
          toastId: customId,
          autoClose: 3000,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["blocks"] });
        const key = crypto.randomUUID();
        const object: any = {};
        object[key] = {
          query: "",
          result_format: "",
          result_style: "",
          title: "",
          extra_information: "",
          table_row_description: "",
          table_columns: "",
          data_source_id: 0,
        } as BlockData;
        setAddedBlocks({ ...object });
        toast.success("Block Created Successfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 1000,
          theme: "colored",
        });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      },
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Partial<IDataSource>[]>();

  useEffect(() => {
    if (searchTerm.length > 0) {
      setSearchResults(fuzzySearch("name", searchTerm, data?.data.data_sources as Partial<IDataSource>[]));
    }
  }, [searchTerm]);

  const sources = searchResults && searchResults?.length > 0 ? searchResults : data && data?.data.data_sources;

  const displayNoDataSourcesAvailable = sources && sources?.length === 0;

  const displayDataSources = sources && sources?.length > 0 && !isFetching;

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div className="pb-[69px] max-w-[1400px]">
      <div className="flex gap-3 items-center hover:cursor-pointer mb-8" onClick={() => window.history.back()}>
        <FaArrowLeftLong />{" "}
        <Typography variant="body2" className="text-grey-900">
          Go Back
        </Typography>
      </div>
      <div className="mb-6">
        <Typography variant="displayxs" className="text-shade-black text-left !font-bold">
          Create New Block
        </Typography>
      </div>

      <div className="flex gap-6">
        <div className="w-[682px]">
          <div className="flex flex-col gap-6">
            {Object.keys(addedBlocks).map((blockKey, key) => (
              <div key={key}>
                <BlockComponentCard
                  allDataSources={data?.data.data_sources as Partial<IDataSource>[]}
                  setBlockProperty={setBlockProperty}
                  getBlockProperty={getBlockProperty}
                  blockKey={blockKey}
                  removeBlockComponent={removeBlockComponent}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-between gap-4 mt-4">
            <div className="flex justify-end">
              <Button onClick={addBlocks} type="button" title="Add" icon={<MdAdd />} />
            </div>
            <Button type="button" title="Save" fullwidth disabled={isSubmitting || isValidated} onClick={handleSubmit} />
          </div>
        </div>
        <div className="w-full max-w-[482px] py-10  border-[1.2px] border-grey-200 rounded-md max-h-[600px] overflow-y-auto">
          <div className="flex justify-between items-center px-5">
            <Typography variant="textxl" className="!font-semibold text-shade-black text-left">
              Included Data Sources
            </Typography>

            <div className="" onClick={() => setModalOpen(true)}>
              <Button icon={<TfiDownload />} title="Upload Another file" type="submit" />
            </div>
          </div>
          {modalOpen && (
            <Modal
              onClose={() => setModalOpen(false)}
              open={modalOpen}
              variant="full"
              children={<AddDataSourceModal onClose={() => setModalOpen(false)} report_id={id} />}
            />
          )}
          <hr className="text-grey-200 mt-6" />

          <div className="px-5">
            <div className="relative rounded-md py-2 mt-3 ">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <img className="text-grey-500 sm:text-sm" alt="search" src={search_icon} />
              </div>

              <input
                className="h-12 px-3 pl-10 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Search data source"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="px-5">
            {isFetching && (
              <div className="mt-4">
                {" "}
                <Loading numberOfLoaders={4} height={10} className="bg-grey-300" />
              </div>
            )}
            {displayNoDataSourcesAvailable && (
              <div className="flex justify-center items-center">
                <Typography variant="displayxs" weight="semibold">
                  No data source available
                </Typography>{" "}
              </div>
            )}
          </div>
          <div className="mt-4 flex gap-4 flex-col px-5">
            {displayDataSources &&
              sources?.map((dataSource: Partial<IDataSource>) => (
                <div key={dataSource.id} className="">
                  <div className="flex gap-4 items-center  hover:cursor-pointer hover:bg-grey-100 px-[2px] py-2">
                    <img
                      src={dataSource.type === "csv" ? csv_icon : dataSource.type === "pdf" ? pdf_icon : xlsx_icon}
                      alt="pdf"
                      className="h-10 w-10"
                    />
                    <Typography variant="body2" className="!font-semibold">
                      {dataSource.name}
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

interface IBlockFormProps {
  removeBlockComponent: Function;
  setBlockProperty: Function;
  getBlockProperty: Function;
  allDataSources: Partial<IDataSource>[];
  blockKey: string;
}

function BlockComponentCard(props: IBlockFormProps) {
  const { getBlockProperty, setBlockProperty, removeBlockComponent, allDataSources, blockKey } = props;

  const [sources, setSources] = useState<any>([]);

  useEffect(() => {
    setSources(allDataSources);
  }, []);

  const [radioValue, setRadioValue] = useState<any>(undefined);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [resultFormat, setResultFormat] = useState<string>("");
  const [resultStyle, setResultStyle] = useState<string>("");
  const [chartType, setChartType] = useState<string>("");

  return (
    <div className="flex flex-col w-full gap-8 py-10 px-5 border-[1.2px] border-grey-200 rounded-md">
      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          open={modalOpen}
          variant="full"
          children={
            <DataSourcesModal
              blockKey={blockKey}
              setBlockProperty={setBlockProperty}
              value={radioValue}
              data={allDataSources}
              setRadioValue={setRadioValue}
              onClose={setModalOpen}
            />
          }
        />
      )}
      <div className="flex flex-col gap-2">
        <Typography variant="body2" className="text-shade-black text-left !font-semibold">
          Block Title
        </Typography>
        <input
          className="h-14 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
          placeholder="Block Title"
          onChange={(e) => {
            setBlockProperty(blockKey, "title", e.target.value);
          }}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
          Query
        </Typography>
        <textarea
          className="h-28 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
          placeholder="Condense this report"
          onChange={(e) => {
            setBlockProperty(blockKey, "query", e.target.value);
          }}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
          Result Format
        </Typography>

        <div className="flex gap-4">
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultFormat === "paragraph",
              }
            )}
            onClick={() => {
              setResultFormat("paragraph");
              setBlockProperty(blockKey, "result_format", "paragraph");
              if (resultFormat === "chart") {
                setRadioValue("");
                setBlockProperty(blockKey, "data_source_id", 0);
              }
            }}
          >
            <img src={paragraph_icon} alt="paragraph" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Paragraph</Typography>
          </div>
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultFormat === "list",
              }
            )}
            onClick={() => {
              setResultFormat("list");
              setBlockProperty(blockKey, "result_format", "list");
              if (resultFormat === "chart") {
                setRadioValue("");
                setBlockProperty(blockKey, "data_source_id", 0);
              }
            }}
          >
            <img src={list_icon} alt="list" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">List</Typography>
          </div>
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultFormat === "table",
              }
            )}
            onClick={() => {
              setResultFormat("table");
              setBlockProperty(blockKey, "result_format", "table");
              if (resultFormat === "chart") {
                setRadioValue("");
                setBlockProperty(blockKey, "data_source_id", 0);
              }
            }}
          >
            <img src={table_icon} alt="table" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Table</Typography>
          </div>

          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultFormat === "chart",
              }
            )}
            onClick={() => {
              setResultFormat("chart");
              setBlockProperty(blockKey, "result_format", "chart");
              if (resultFormat !== "chart") {
                setRadioValue("");
                setBlockProperty(blockKey, "data_source_id", 0);
              }
            }}
          >
            <img src={chart_icon} alt="chart" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Chart</Typography>
          </div>
        </div>
      </div>

      {resultFormat === "table" && (
        <>
          <div className="flex flex-col gap-2">
            <Typography variant="body2" className="text-shade-black text-left !font-semibold">
              Table Prompt
            </Typography>
            <Typography variant="textsm" className="text-shade-black text-left !font-meduim">
              Row description
            </Typography>
            <input
              className="h-14 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Row Description"
              onChange={(e) => {
                setBlockProperty(blockKey, "table_row_description", e.target.value);
              }}
            />
          </div>

          <div className="flex flex-col gap-2">
            <Typography variant="textsm" className="text-shade-black text-left !font-meduim">
              Columns
            </Typography>
            <input
              className="h-14 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Columns"
              onChange={(e) => {
                setBlockProperty(blockKey, "table_columns", e.target.value);
              }}
            />
          </div>
        </>
      )}

      {resultFormat === "chart" && (
        <>
          <div className="flex flex-col gap-2">
            <Typography variant="body2" className="!font-semibold text-shade-black text-left">
              Chart Type
            </Typography>

            <div className="flex gap-4">
              <div
                className={classNames(
                  "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                  {
                    "bg-primary-50": chartType === "pie_chart",
                  }
                )}
                onClick={() => {
                  setChartType("pie_chart");
                  setBlockProperty(blockKey, "chart_type", "pie_chart");
                }}
              >
                <img src={pie_chart_icon} alt="brief" className="w-[20px] h-[20px]" />
                <Typography variant="textsm">Pie Chart</Typography>
              </div>
              <div
                className={classNames(
                  "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                  {
                    "bg-primary-50": chartType === "bar_chart",
                  }
                )}
                onClick={() => {
                  setChartType("bar_chart");
                  setBlockProperty(blockKey, "chart_type", "bar_chart");
                }}
              >
                <img src={bar_chart_icon} alt="comprehensive" className="w-[20px] h-[20px]" />
                <Typography variant="textsm">Bar Chart</Typography>
              </div>
              <div
                className={classNames(
                  "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
                  {
                    "bg-primary-50": chartType === "line_chart",
                  }
                )}
                onClick={() => {
                  setChartType("line_chart");
                  setBlockProperty(blockKey, "chart_type", "line_chart");
                }}
              >
                <img src={line_chart_icon} alt="detailed" className="w-[20px] h-[20px]" />
                <Typography variant="textsm">Line Chart</Typography>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <Typography variant="body2" className="text-shade-black text-left !font-semibold">
              Chart Instruction
            </Typography>
            <input
              className="h-14 mt-[2px] p-4 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Chart instruction"
              onChange={(e) => {
                setBlockProperty(blockKey, "chart_instruction", e.target.value);
              }}
            />
          </div>
        </>
      )}

      <div className="flex flex-col gap-2">
        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
          Result Style
        </Typography>

        <div className="flex gap-4">
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultStyle === "brief",
              }
            )}
            onClick={() => {
              setResultStyle("brief");
              setBlockProperty(blockKey, "result_style", "brief");
            }}
          >
            <img src={brief_icon} alt="brief" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Brief</Typography>
          </div>
          <div
            className={classNames(
              "w-[120px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultStyle === "comprehensive",
              }
            )}
            onClick={() => {
              setResultStyle("comprehensive");
              setBlockProperty(blockKey, "result_style", "comprehensive");
            }}
          >
            <img src={comprehensive_icon} alt="comprehensive" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Comprehensive</Typography>
          </div>
          <div
            className={classNames(
              "w-[88px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 p-2 flex flex-col gap-2 items-center justify-center hover:cursor-pointer",
              {
                "bg-primary-50": resultStyle === "detailed",
              }
            )}
            onClick={() => {
              setResultStyle("detailed");
              setBlockProperty(blockKey, "result_style", "detailed");
            }}
          >
            <img src={detailed_icon} alt="detailed" className="w-[20px] h-[20px]" />
            <Typography variant="textsm">Detailed</Typography>
          </div>
        </div>
      </div>

      <div className="flex justify-start flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div
              className={classNames("text-primary-700 flex gap-1 items-center hover:cursor-pointer", {
                "opacity-50 pointer-events-none": !resultFormat,
              })}
              onClick={() => setModalOpen(true)}
            >
              <GoPlus className="!font-bold h-6 w-6 mb-1" />
              <div className="flex items-center gap-2">
                <Typography variant="body2" className="!font-semibold text-primary-700">
                  Add Sources
                </Typography>
              </div>
            </div>

            <Typography variant="textsm" className="!font-semibold text-grey-500">
              (Select result format first)
            </Typography>
          </div>
          <img src={red_trash_icon} alt="Delete" className="cursor-pointer" onClick={() => removeBlockComponent(blockKey)} />
        </div>

        <div
          className={classNames("h-[60px] bg-grey-50 px-2 flex items-center ", {
            "hover:bg-grey-100": radioValue,
          })}
        >
          {radioValue ? (
            <div className="flex justify-between items-center  gap-4 p-2 rounded-md">
              <div className="flex gap-3 items-center">
                <img src={radioValue.type === "csv" ? csv_icon : radioValue.type === "pdf" ? pdf_icon : xlsx_icon} alt="pdf" className="h-10 w-10" />
                <Typography variant="textsm" weight="medium">
                  {radioValue.name}
                </Typography>
              </div>

              <img
                src={delete_icon}
                alt="delete"
                onClick={() => {
                  setRadioValue("");
                  setBlockProperty(blockKey, "data_source_id", 0);
                }}
                className="cursor-pointer"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-2 mt-6">
        <Typography variant="body2" className="!font-semibold text-shade-black text-left">
          Extra Information (Optional)
        </Typography>
        <textarea
          className="h-28 mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
          onClick={(e: any) => {
            setBlockProperty(blockKey, "extra_information", e.target.value);
          }}
        />
      </div>
    </div>
  );
}

interface IDataSourceModal {
  data: Partial<IDataSource>[];
  setRadioValue: (e: any) => void;
  value: any;
  onClose: (close: boolean) => void;
  setBlockProperty: Function;
  blockKey: string;
}

function DataSourcesModal(props: IDataSourceModal) {
  const { data, setRadioValue, onClose, value, setBlockProperty, blockKey } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Partial<IDataSource>[]>();

  useEffect(() => {
    if (searchTerm.length > 0) {
      setSearchResults(fuzzySearch("name", searchTerm, data));
    }
  }, [searchTerm]);

  const source = searchResults && searchResults?.length > 0 ? searchResults : data;

  const displayDataSource = source?.length > 0;

  const displayNoDataSource = source?.length === 0;

  return (
    <div className="flex bg-[#FFF] flex-col rounded-md w-auto">
      <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
        <Typography variant="textxl" className="font-semibold">
          Choose File Add Source
        </Typography>

        <div onClick={() => onClose(false)} className="hover:cursor-pointer h-6 w-6">
          <IoMdClose className="w-full h-full" />
        </div>
      </div>
      {displayNoDataSource && (
        <div className="flex justify-center items-center h-56 px-8">
          <Typography variant="displaysm" className="text-grey-500">
            No Data Source Found
          </Typography>{" "}
        </div>
      )}
      {displayDataSource && (
        <>
          <div className="mt-3">
            <div className="relative rounded-md px-6 pt-2">
              <div className="pointer-events-none absolute inset-y-0 left-6 flex items-center pl-3">
                <img className="text-grey-500 sm:text-sm" alt="search" src={search_icon} />
              </div>

              <input
                className="h-12 px-3 pl-10 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Search data source"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="px-6 py-4 flex gap-4 flex-col w-[470px]  h-[350px] overflow-auto">
            {source?.map((data_source: Partial<IDataSource>, index) => (
              <div
                key={data_source.id}
                className="flex items-center gap-4 hover:cursor-pointer"
                onClick={() => {
                  setRadioValue(data_source);
                  setBlockProperty(blockKey, "data_source_id", data_source.id);
                }}
              >
                <input
                  type="radio"
                  id="radioColor"
                  name="fileType"
                  value={value}
                  checked={Number(value?.id) === data_source.id}
                  onChange={() => {
                    setRadioValue(data_source);
                    setBlockProperty(blockKey, "data_source_id", data_source.id);
                  }}
                  className="w-6 h-6"
                />
                <img
                  src={data_source.type === "csv" ? csv_icon : data_source.type === "pdf" ? pdf_icon : xlsx_icon}
                  alt="pdf"
                  className="h-10 w-10"
                />
                <Typography variant="body2" className="font-medium">
                  {data_source.name}
                </Typography>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="flex justify-end items-center px-6 mb-6 mt-4">
        <Button type="button" title="Continue" onClick={() => onClose(false)} />
      </div>{" "}
    </div>
  );
}
