import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Progress,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import {
  MdAdd,
  MdArticle,
  MdClose,
  MdDelete,
  MdList,
  MdNewspaper,
  MdPieChart,
  MdSettings,
  MdSpaceBar,
  MdSummarize,
  MdTableBar,
  MdTableChart,
  MdTimeline,
  MdUpload,
  MdWrapText,
} from "react-icons/md";
import DataSourcesCard, { DataSourceItem } from "../components/DataSourceCard";
import { useNavigate, useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { bulkCreateBlocks, createFileDataSource, createNewBlock, getAllDataSources, getReportDataSources } from "../services/reports.service";
import { BlockData } from "types";
//const BACKEND = process.env['REACT_APP_BACKEND_URL'] as string

export function CreateBlocksPage() {
  const { report_id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const [dataSources, setDataSources] = useState<Array<any>>([]);
  const [addedBlocks, setAddedBlocks] = useState<any>({});

  useEffect(() => {
    getReportDataSources(parseInt(report_id as string)).then((response) => {
      setDataSources(response.data["data"]["data_sources"]);
    });
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const removeBlockComponent = (key: string) => {
    delete addedBlocks[key];
    setAddedBlocks({ ...addedBlocks });
  };

  const setBlockProperty = (key: string, propertyName: string, value: string) => {
    addedBlocks[key][propertyName] = value;
    setAddedBlocks({ ...addedBlocks });
  };

  const getBlockProperty = (key: string, propertyName: string) => {
    return addedBlocks[key][propertyName];
  };

  return (
    <Flex direction={"row"} height={"100%"} width={"100%"}>
      <SideBar />

      <Flex direction={"column"} paddingLeft={"150px"} paddingTop={"50px"} width={"100%"} paddingY={"20px"} height={"100%"}>
        <HStack width={"600px"}>
          <Text>Create New Block</Text>
          <Spacer />
        </HStack>

        {Object.keys(addedBlocks).map((blockKey, key) => (
          <BlockComponent
            allDataSources={dataSources}
            setBlockProperty={setBlockProperty}
            getBlockProperty={getBlockProperty}
            blockKey={blockKey}
            removeBlockComponent={removeBlockComponent}
          />
        ))}

        <HStack width={"600px"} marginTop={"30px"}>
          <Spacer />
          <Button
            backgroundColor="#a080ff"
            width={"100px"}
            color={"white"}
            onClick={() => {
              const key = crypto.randomUUID();
              const object: any = {};
              object[key] = {
                //notefib: toInterface
                query: "",
                result_format: "",
                result_style: "",
                title: "",
                extra_information: "",
                table_row_description: "",
                table_columns: "",
              } as BlockData;
              setAddedBlocks({ ...addedBlocks, ...object });
            }}
          >
            {" "}
            <HStack>
              <MdAdd />
              <Text>Add</Text>
            </HStack>{" "}
          </Button>
        </HStack>

        <Button
          marginTop={"30px"}
          backgroundColor="#a080ff"
          width={"600px"}
          color={"white"}
          onClick={() => {
            bulkCreateBlocks(report_id as string, addedBlocks)
              .then((result) => {
                toast({
                  title: "Successfully Created",
                  status: "success",
                  position: "top",
                  duration: 2000,
                });
                navigate(-1);
              })
              .catch((error) => {
                toast({
                  title: "Error Creating Blocks",
                  status: "error",
                  position: "top",
                  duration: 2000,
                });
              });
          }}
        >
          {" "}
          <HStack>
            <Text>Save</Text>
          </HStack>{" "}
        </Button>
      </Flex>

      <DataSourcesPane />
    </Flex>
  );
}

function SideBar() {
  const navigate = useNavigate();

  return <Flex width={"5%"} height={"100vh"}></Flex>;
}

function DataSourcesPane() {
  const [dataSources, setDataSources] = useState<Array<any>>([]);
  const { report_id } = useParams();
  const [fileUploading, setFileUploading] = useState<any>(null);
  const [shouldReloadDataSources, setShouldReloadDataSources] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    getReportDataSources(parseInt(report_id as string)).then((response) => {
      setDataSources(response.data["data"]["data_sources"]);
    });
  }, [shouldReloadDataSources]);

  return (
    <Flex width={"300px"} height={"100vh"} backgroundColor={"whitesmoke"} flexDirection={"column"} paddingY={"30px"} paddingX={"30px"}>
      <Text fontWeight={"600"} textAlign={"center"}>
        Included Data Sources
      </Text>
      <Input marginTop={"20px"} placeholder="Search" />

      <VStack marginTop={"15px"}>
        {dataSources.map((value, index) => (
          <DataSourceItem key={index} data={value} soureType={value.type} />
        ))}
      </VStack>

      <Divider />
      {!fileUploading ? (
        <></>
      ) : (
        <Box marginTop={"10px"} backgroundColor={"whitesmoke"} minHeight={"100px"} width={"100%"} marginX={"auto"}>
          <DataSourceItem data={fileUploading} soureType={fileUploading.type} />
          <Progress width={"100%"} size={"xs"} colorScheme={"purple"} isIndeterminate />
        </Box>
      )}

      <Button
        backgroundColor="#a080ff"
        isDisabled={fileUploading}
        color={"white"}
        marginTop={"30px"}
        onClick={() => {
          document.getElementById("fileUploadForm")?.click();
        }}
        _hover={{
          backgroundColor: "#a080ff",
          color: "white",
        }}
      >
        {" "}
        <HStack>
          {" "}
          <MdUpload /> <Text fontSize={"13px"}>Upload Another File</Text>
        </HStack>{" "}
      </Button>

      <Input
        style={{ display: "none" }}
        id="fileUploadForm"
        type="file"
        multiple={true}
        accept="file/pdf"
        onChange={(e) => {
          const uploadedFile: File = e.target!.files![0];
          const formData = new FormData();
          setFileUploading({ name: uploadedFile.name, type: "pdf" });
          formData.append("file", uploadedFile as Blob, uploadedFile!.name);
          formData.append("data_source_name", uploadedFile!.name);
          formData.append("report_id", report_id as string);

          createFileDataSource(formData).then((result) => {
            setFileUploading(null);
            setShouldReloadDataSources(!shouldReloadDataSources);
            toast({
              title: "Upload Successful",
              position: "top",
              status: "success",
            });
          });
        }}
      />
    </Flex>
  );
}

function BlockComponent(props: {
  removeBlockComponent: Function;
  setBlockProperty: Function;
  getBlockProperty: Function;
  allDataSources: Array<any>;

  blockKey: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [radioValue, setRadioValue] = useState<string | undefined>(undefined);

  const choiceSelectionStyle = {
    borderColor: "#a080ff",
    borderWidth: "3px",
    padding: "4px",
  };

  return (
    <Box width={"600px"} marginTop={"30px"} borderWidth={"1px"} paddingX={"40px"} paddingTop={"20px"}>
      {/* <Box backgroundColor={'red'}> <Text color={'white'}>One or more fields are not set </Text> </Box> */}

      <FormControl>
        <FormLabel>Block Title</FormLabel>
        <Input
          onChange={(e) => {
            props.setBlockProperty(props.blockKey, "title", e.target.value);
          }}
        ></Input>
      </FormControl>
      <Box marginTop={"30px"}>
        <Box marginTop={"10px"} marginX={"auto"}>
          <FormControl>
            <FormLabel>Query</FormLabel>
            <Textarea
              onChange={(e) => {
                props.setBlockProperty(props.blockKey, "query", e.target.value);
              }}
            />
          </FormControl>
          <HStack width={"600px"} marginTop={"20px"}>
            <Button
              backgroundColor="transparent"
              width={"100px"}
              color={"#a080ff"}
              _hover={{
                backgroundColor: "transparent",
              }}
              onClick={onOpen}
            >
              {" "}
              <HStack>
                <MdAdd /> <Text>Add Sources</Text>
              </HStack>{" "}
            </Button>

            <Box width={"65%"} />
            <IconButton
              backgroundColor={"transparent"}
              color={"red"}
              onClick={() => {
                props.removeBlockComponent(props.blockKey);
              }}
              aria-label="remove"
              icon={<MdDelete />}
            />

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Choose source</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <RadioGroup
                    onChange={(value) => {
                      setRadioValue(value);
                      props.setBlockProperty(props.blockKey, "data_source_id", JSON.parse(value as string)["id"]);
                    }}
                    value={radioValue}
                  >
                    {props.allDataSources.map((data, index) => {
                      return (
                        <Radio onSelect={() => alert("selected")} value={JSON.stringify(data)}>
                          <DataSourcesCard data={data} soureType={data.type} />
                        </Radio>
                      );
                    })}
                  </RadioGroup>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </HStack>
          {radioValue ? (
            <HStack>
              <DataSourcesCard data={JSON.parse(radioValue)} soureType={"pdf"} />
              <IconButton backgroundColor={"transparent"} aria-label="remove" icon={<MdClose />} />
            </HStack>
          ) : (
            <></>
          )}
        </Box>
        <Box marginTop={"60px"}>
          <Text fontWeight={600}>Result Format</Text>
          <HStack marginTop={"20px"} spacing={"30px"}>
            <VStack
              style={props.getBlockProperty(props.blockKey, "result_format") === "paragraph" ? choiceSelectionStyle : {}}
              cursor={"pointer"}
              tabIndex={1}
              onClick={() => props.setBlockProperty(props.blockKey, "result_format", "paragraph")}
            >
              <Box borderWidth={"1px"} padding={"10px"}>
                <MdWrapText />
              </Box>
              <Text>Paragraph</Text>
            </VStack>
            <VStack
              style={props.getBlockProperty(props.blockKey, "result_format") === "list" ? choiceSelectionStyle : {}}
              cursor={"pointer"}
              tabIndex={1}
              onClick={() => props.setBlockProperty(props.blockKey, "result_format", "list")}
            >
              <Box borderWidth={"1px"} padding={"10px"}>
                <MdList />
              </Box>
              <Text>List</Text>
            </VStack>
            <VStack
              style={props.getBlockProperty(props.blockKey, "result_format") === "table" ? choiceSelectionStyle : {}}
              cursor={"pointer"}
              tabIndex={1}
              onClick={() => props.setBlockProperty(props.blockKey, "result_format", "table")}
            >
              <Box borderWidth={"1px"} padding={"10px"}>
                <MdTableChart />
              </Box>
              <Text>Table</Text>
            </VStack>
          </HStack>
        </Box>

        {props.getBlockProperty(props.blockKey, "result_format") === "table" ? (
          <Box marginTop={"30px"}>
            <Text fontWeight={600}>Table Prompts</Text>
            <VStack marginTop={"20px"} spacing={"10px"}>
              <FormControl>
                <FormLabel>Row Description</FormLabel>
                <Input
                  onChange={(e) => {
                    props.setBlockProperty(props.blockKey, "table_row_description", e.target.value);
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Columns</FormLabel>
                <Input
                  onChange={(e) => {
                    props.setBlockProperty(props.blockKey, "table_columns", e.target.value);
                  }}
                />
              </FormControl>
            </VStack>
          </Box>
        ) : (
          <></>
        )}

        <Box marginTop={"60px"}>
          <Text fontWeight={600}>Result Style</Text>
          <HStack marginTop={"20px"} spacing={"30px"}>
            <VStack
              style={props.getBlockProperty(props.blockKey, "result_style") === "brief" ? choiceSelectionStyle : {}}
              cursor={"pointer"}
              tabIndex={1}
              onClick={() => props.setBlockProperty(props.blockKey, "result_style", "brief")}
            >
              <Box borderWidth={"1px"} padding={"10px"}>
                <MdSummarize />
              </Box>
              <Text>Brief</Text>
            </VStack>
            <VStack
              style={props.getBlockProperty(props.blockKey, "result_style") === "comprehensive" ? choiceSelectionStyle : {}}
              cursor={"pointer"}
              tabIndex={1}
              onClick={() => props.setBlockProperty(props.blockKey, "result_style", "comprehensive")}
            >
              <Box borderWidth={"1px"} padding={"10px"}>
                <MdArticle />
              </Box>
              <Text>Comprehensive</Text>
            </VStack>
            <VStack
              style={props.getBlockProperty(props.blockKey, "result_style") === "detailed" ? choiceSelectionStyle : {}}
              cursor={"pointer"}
              tabIndex={1}
              onClick={() => props.setBlockProperty(props.blockKey, "result_style", "detailed")}
            >
              <Box borderWidth={"1px"} padding={"10px"}>
                <MdNewspaper />
              </Box>
              <Text>Detailed</Text>
            </VStack>
          </HStack>
        </Box>

        <Box marginTop={"30px"}>
          <FormControl>
            <FormLabel>Extra Information {"(Optional)"}</FormLabel>
            <Textarea
              onChange={(e) => {
                props.setBlockProperty(props.blockKey, "extra_information", e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </Box>
      <Box marginTop={"30px"}>
        <Box marginX={"auto"} marginTop={"10px"}>
          {/* <FormControl>
        <FormLabel>Query</FormLabel>
        <Textarea/>
    </FormControl>   */}
        </Box>
      </Box>
    </Box>
  );
}

function DataSourcesSideBar() {}
