import { Button } from "components";
import Typography from "components/Typography";
import { useNavigate } from "react-router";

export function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen">
        <Typography variant="display" className="text-shade-black">
          404{" "}
        </Typography>{" "}
        <Typography variant="displayxl" className="text-shade-black mb-2">
          PAGE NOT FOUND{" "}
        </Typography>{" "}
        <Button title="Home" onClick={() => navigate("/reports")} />
      </div>
      ;
    </>
  );
}
