import { Outlet } from "react-router";
import "./App.css";
import AppProvider from "providers/AppContext";

function App() {
  return (
    <div className="App">
      <AppProvider>
        <Outlet />
      </AppProvider>
    </div>
  );
}

export default App;
