import { useState } from "react";
import Typography from "./Typography";
import { IoMdClose } from "react-icons/io";
import { Button } from "./Button";
import { DropZone, IDropzoneType } from "./DropZone";
import classNames from "classnames";
import { FileTypes, ISeparatorEnumValue, ModelInView } from "types";
import { useUploadDataSource } from "services";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { csv_icon, docx_icon, pdf_icon, xlsx_icon } from "assets/svg";

interface IAddDataSource {
  onClose: () => void;
  report_id?: string;
}

const fileTypes: { name: string; icon: React.ReactNode }[] = [
  {
    name: "pdf",
    icon: <img src={pdf_icon} alt="pdf" className="h-10 w-10" />,
  },
  {
    name: "csv",
    icon: <img src={csv_icon} alt="csv" className="h-10 w-10" />,
  },
  {
    name: "xlsx",
    icon: <img src={xlsx_icon} alt="xlsx" className="h-10 w-10" />,
  },
  {
    name: "docx",
    icon: <img src={docx_icon} alt="docx" className="h-10 w-10" />,
  },
];

export function AddDataSourceModal(props: IAddDataSource) {
  const { onClose, report_id } = props;

  const [modalInView, setModalInView] = useState<ModelInView>(ModelInView.DATATYPE);
  const [selectedFileType, setSelectedFileType] = useState<FileTypes>(FileTypes.PDF);
  const [fileName, setFileName] = useState<string>();
  const [separator, setSeparator] = useState<ISeparatorEnumValue>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [file, setFile] = useState<File>();

  const { uploadDatasource } = useUploadDataSource();

  const formData = new FormData();

  const fileType = file?.type.split("/")[1] as FileTypes;

  const xlsxFileType = fileType !== FileTypes.PDF && fileType !== FileTypes.CSV ? FileTypes.XLSX && fileType !== FileTypes.DOCX : fileType;

  const handleUpload = async () => {
    setIsSubmitting(true);
    formData.append("file", file as File);
    formData.append("data_source_name", fileName as string);
    formData.append("type", xlsxFileType as string);
    formData.append("separator", separator as ISeparatorEnumValue);
    if (report_id) {
      formData.append("report_id", report_id as string);
    }

    const customId = "custom-toast-id";

    uploadDatasource(formData, {
      onError: (error, variables, context) => {
        toast.error("Error creating Datasource", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["datasources"] });

        if (report_id) {
          queryClient.invalidateQueries({ queryKey: ["report-datasources", report_id] });
        }

        toast.success("Datasource Created Successfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });

        setTimeout(() => {
          onClose();
        }, 2000);
      },
    });
  };

  const disableButton = !fileName || !file || (fileType === FileTypes.CSV && !separator) || isSubmitting;

  return (
    <div className={classNames("flex bg-[#FFF] flex-col rounded-md w-[470px]")}>
      <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
        <>
          {modalInView === ModelInView.DATATYPE && (
            <Typography variant="textxl" className="font-semibold">
              Choose File Type
            </Typography>
          )}
          {modalInView !== ModelInView.DATATYPE && (
            <div className="flex items-center gap-2">
              <img
                src={
                  selectedFileType === "csv" ? csv_icon : selectedFileType === "pdf" ? pdf_icon : selectedFileType === "docx" ? docx_icon : xlsx_icon
                }
                alt="file type"
                className="h-8 w-8"
              />
              <Typography variant="textxl" className="font-semibold">
                Add
              </Typography>
              <Typography variant="textxl" className="font-semibold uppercase">
                {selectedFileType}
              </Typography>
              <Typography variant="textxl" className="font-semibold">
                File
              </Typography>
            </div>
          )}
        </>

        <div onClick={() => onClose()} className="hover:cursor-pointer h-6 w-6">
          <IoMdClose className="w-full h-full" />
        </div>
      </div>
      {modalInView === ModelInView.DATATYPE && (
        <div className="">
          <div className="flex flex-col gap-4 p-6">
            {" "}
            {fileTypes.map((type, index) => (
              <div className="flex items-center hover:cursor-pointer" key={index} onClick={(e) => setSelectedFileType(type.name as FileTypes)}>
                <input
                  type="radio"
                  id="radioColor"
                  name="fileType"
                  value={type.name}
                  checked={selectedFileType === type.name}
                  onChange={(e) => setSelectedFileType(e.target.value as FileTypes)}
                  className="mr-4 w-6 h-6 hover:cursor-pointer"
                />
                <div className="mr-3">{type.icon}</div>
                <Typography variant="textsm" className="font-medium uppercase">
                  {type.name} file
                </Typography>
              </div>
            ))}
          </div>
          <div className="flex justify-end px-6 pb-6 pt-4 border-t-[1px] border-t-grey-100">
            {" "}
            <Button title="Continue" onClick={() => setModalInView(ModelInView.UPLOAD)} />
          </div>{" "}
        </div>
      )}
      {modalInView === ModelInView.UPLOAD && (
        <div className="">
          <div className="flex flex-col gap-2 px-6 mt-3">
            <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
              Name
            </Typography>
            <input
              className="h-12 mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Title"
              onChange={(event) => {
                setFileName(event.target.value);
              }}
            />
          </div>
          <div className="flex flex-col gap-2 px-6 mt-5 mb-6">
            <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
              Upload File
            </Typography>
            <div className="flex flex-col gap-2">
              <DropZone dropZoneType={IDropzoneType.SMALL} setFile={setFile} fileType={selectedFileType} />{" "}
              <div className="flex gap-1">
                <Typography variant="textsm" className="font-normal text-grey-500 text-start">
                  Supported format:
                </Typography>
                <Typography variant="textsm" className="font-normal text-grey-500  uppercase">
                  {selectedFileType}
                </Typography>
              </div>
            </div>
          </div>
          {selectedFileType === FileTypes.CSV && (
            <>
              <div className="flex flex-col gap-2 px-6 mt-5 mb-8">
                <Typography variant="body2" className="!font-semibold text-shade-black text-left">
                  Select a Separator
                </Typography>

                <div className="flex gap-3 ">
                  <div
                    className={classNames(
                      "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 flex items-center justify-center hover:cursor-pointer",
                      {
                        "bg-primary-50": separator === ISeparatorEnumValue.COMMA,
                      }
                    )}
                    onClick={() => {
                      setSeparator(ISeparatorEnumValue.COMMA);
                    }}
                  >
                    <div className="flex justify-center gap-2 items-center w-full p-2">
                      <Typography variant="textsm" className="text-grey-500">
                        Comma
                      </Typography>
                      <Typography variant="textsm" className="text-grey-500">
                        (,)
                      </Typography>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                      {
                        "bg-primary-50": separator === ISeparatorEnumValue.TAB,
                      }
                    )}
                    onClick={() => {
                      setSeparator(ISeparatorEnumValue.TAB);
                    }}
                  >
                    <div className="flex justify-center gap-2 items-center w-full p-2">
                      <Typography variant="textsm" className="text-grey-500">
                        Tab
                      </Typography>
                      <Typography variant="textsm" className="text-grey-500">
                        (\t)
                      </Typography>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                      {
                        "bg-primary-50": separator === ISeparatorEnumValue.SEMICOLON,
                      }
                    )}
                    onClick={() => {
                      setSeparator(ISeparatorEnumValue.SEMICOLON);
                    }}
                  >
                    <div className="flex justify-center gap-2 items-center w-full p-2">
                      <Typography variant="textsm" className="text-grey-500">
                        Semicolon
                      </Typography>
                      <Typography variant="textsm" className="text-grey-500">
                        (;)
                      </Typography>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                      {
                        "bg-primary-50": separator === ISeparatorEnumValue.PIPE,
                      }
                    )}
                    onClick={() => {
                      setSeparator(ISeparatorEnumValue.PIPE);
                    }}
                  >
                    <div className="flex justify-center gap-2 items-center w-full p-2">
                      <Typography variant="textsm" className="text-grey-500">
                        Pipe
                      </Typography>
                      <Typography variant="textsm" className="text-grey-500">
                        (|)
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="flex justify-between items-center px-6 pb-6 pt-4  border-t-[1px] border-t-grey-100">
            <Button type="button" title="Cancel" color="info" onClick={() => onClose()} />
            <Button disabled={disableButton} type="button" title="Save" onClick={handleUpload} />
          </div>{" "}
        </div>
      )}
    </div>
  );
}
