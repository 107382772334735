import { useNavigate } from "react-router";
import Typography from "./Typography";
import { csv_icon, pdf_icon, xlsx_icon } from "assets/svg";
import { useState } from "react";

interface IDataSourceComponent {
  name: string;
  id: number;
  updated: string;
  type: string;
}
export default function DataSourceComponent(props: IDataSourceComponent) {
  const navigate = useNavigate();

  const { name, id, updated, type } = props;

  const date = new Date(updated).toLocaleDateString();
  const time = new Date(updated).toLocaleTimeString();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const icon = type === "csv" ? csv_icon : type === "pdf" ? pdf_icon : xlsx_icon;

  return (
    <div className="flex justify-between items-center cursor-pointer w-full py-4 px-3 border-[1px] border-grey-200 rounded-lg hover:bg-grey-100">
      <div className="flex gap-3 items-center w-full">
        <img src={icon} alt="pdf" className="w-[60px] h-[60px] rounded-lg" />
        <div className="flex gap-2 justify-center flex-col items-start">
          <Typography variant="body2" className="text-shade-black font-semibold">
            {`${name}.${type}`}
          </Typography>
          <Typography variant="textsm" className="text-grey-500 font-regular">
            Last Updated {date} {time}
          </Typography>
        </div>
      </div>
    </div>
  );
}
