import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { ReportsPage } from "./pages/Reports";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { DataSourcesPage } from "./pages/DataSources";
import { CreatePDFDataSource } from "./pages/PDFSource";
import { Blocks } from "./pages/Blocks";
import { NewReportPage } from "./pages/NewReport";
import { CreateBlocksPage } from "./pages/CreateBlocks";
import { TemplatesPage } from "./pages/Templates";
import { EditBlockPage } from "./pages/EditBlock";
import { PDFReport } from "./pages/PDFReport";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { routes } from "router";
import { Reports } from "pages/new-reports";
import { Report } from "pages/report";
import { CreateBlock } from "pages/block/create-block";
import { EditBlock } from "pages/block/edit-block";
import ErrorBoundary from 'components/ErrorBoundary';
import { ErrorFallback } from 'components';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <ReportsPage />,
  },
  {
    path: "/reports",
    element: <ReportsPage />,
  },
  {
    path: "/reports/templates",
    element: <TemplatesPage />,
  },
  {
    path: "/reports/new",
    element: <NewReportPage />,
  },
  {
    path: "/reports/:report_id/",
    element: <Blocks />,
  },
  {
    path: "/reports/:report_id/blocks/:block_id/edit",
    element: <EditBlockPage />,
  },
  {
    path: "/reports/:report_id/pdf",
    element: <PDFReport />,
  },
  {
    path: "/reports/:report_id/blocks/new/many",
    element: <CreateBlocksPage />,
  },

  {
    path: "/data-sources",
    element: <DataSourcesPage />,
  },
  {
    path: "/data-sources/new/pdf",
    element: <CreatePDFDataSource />,
  },
  {
    path: "/reports",
    element: <Reports />,
  },
  {
    path: "/reports/:report_id/",
    element: <Report />,
  },
  {
    path: "/data-sources",
    element: <Report />,
  },
  {
    path: "/blocks/:id/create",
    element: <CreateBlock />,
  },
  {
    path: "/blocks/:id/edit:id",
    element: <EditBlock />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <ChakraProvider>
  <ErrorBoundary fallback={<ErrorFallback />}>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <ToastContainer />
        <RouterProvider router={routes} />
      </React.StrictMode>
    </QueryClientProvider>
  </ErrorBoundary>
  // </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
