import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ICreateBlock, ICreateBlockPayload, ICreateReport, IEditBlockPayload, IResultTypes } from "types";
import {
  createReportApi,
  fetchBlocksApi,
  fetchDataSourcesApi,
  fetchReportsApi,
  editBlockResultApi,
  fetchBlockApi,
  uploadDataSource,
  fetchBlockResultApi,
  createBlockResultApi,
  updateBlockApi,
  chatQueryApi,
  fetchBlockImageApi,
  deleteReportApi,
  fetchBlockReferencesApi,
  getReportDataSourcesApi,
  getReportDetails,
} from "./api";

const BACKEND = process.env["REACT_APP_BACKEND_URL"] as string;

export const getAllDataSources = async () => {
  return axios.get(BACKEND + "/data-sources/all");
};

export const getReportDataSources = async (reportID: number) => {
  return axios.get(BACKEND + "/reports/" + reportID + "/data-sources");
};

export const createNewBlock = async (
  reportID: string,
  name: string,
  query: string,
  dataSourceID: string
  //viewType,
  // viewData.
) => {
  return axios.post(BACKEND + `/reports/${reportID}/blocks`, {
    title: name,
    query: query,
    data_source_id: dataSourceID,
  });
};

export const bulkCreateBlocks = async (
  reportID: string,
  blocksData: any
  //viewType,
  // viewData.
) => {
  return axios.post(BACKEND + `/reports/${reportID}/blocks-bulk`, {
    blocks_data: blocksData,
  });
};

export const createNewReport = async (title: string, dataSources: any) => {
  return axios.post(`${BACKEND}/reports`, {
    title: title,
    data_sources: dataSources,
  });
};

export const createNewTemplate = async (title: string) => {
  return axios.post(`${BACKEND}/reports/templates`, {
    title: title,
  });
};

export const getAllBlockResults = async (reportID: string) => {
  return axios.get(`${BACKEND}/reports/${reportID}/results`);
};

export const getBlockResult = async (reportID: string, blockID: string, isRedoing: boolean) => {
  return axios.get(`${BACKEND}/reports/${reportID}/blocks/${blockID}/result?is_redoing=${isRedoing}`);
};

export const updateBlockResult = async (reportID: string, blockID: string, newContent: string) => {
  return axios.patch(`${BACKEND}/reports/${reportID}/blocks/${blockID}/result`, {
    content: newContent,
  });
};

export const getAllBlocks = async (reportID: string) => {
  return axios.get(`${BACKEND}/reports/${reportID}/blocks`);
};

export const getBlock = async (reportID: string, blockID: string) => {
  return axios.get(`${BACKEND}/reports/${reportID}/blocks/${blockID}`);
};
export const updateBlock = async (reportID: string, blockID: string, blockData: any) => {
  return axios.put(`${BACKEND}/reports/${reportID}/blocks/${blockID}`, blockData);
};

export const queryBlock = async (reportID: string, blockID: string, previousResponse: string, query: string) => {
  return axios.post(`${BACKEND}/reports/${reportID}/blocks/${blockID}/queries`, {
    previous_response: previousResponse,
    query,
  });
};

export const createFileDataSource = async (formData: FormData) => {
  return axios.post(`${BACKEND}/data-sources/files`, formData);
};

export const useCreateReport = () => {
  const { mutate: createReport } = useMutation({
    mutationFn: (data: ICreateReport) => createReportApi(data),
  });

  return {
    createReport,
  };
};

export const useFetchReports = () => {
  const { isFetching, data, error } = useQuery({
    queryKey: ["reports"],
    queryFn: fetchReportsApi,
  });

  return {
    isFetching,
    data,
    error,
  };
};

export const useFetchDataSources = () => {
  const { isFetching, data, error } = useQuery({
    queryKey: ["datasources"],
    queryFn: fetchDataSourcesApi,
  });

  return {
    isFetching,
    data,
    error,
  };
};

export const useFetchBlocks = (id: string) => {
  const { isFetching, data, error } = useQuery({
    queryKey: ["blocks", id],
    queryFn: () => fetchBlocksApi(id),
  });

  return {
    isFetching,
    data,
    error,
  };
};

export const useEditBlockResult = () => {
  const { mutate: editBlock } = useMutation({
    mutationFn: (data: IEditBlockPayload) => editBlockResultApi(data),
  });

  return {
    editBlock,
  };
};

export const useCreateBlock = () => {
  const { mutate: createBlock } = useMutation({
    mutationFn: (data: ICreateBlockPayload) => createBlockResultApi(data),
    onError(error, variables, context) {
      console.log(error, variables, context);
    },
  });

  return {
    createBlock,
  };
};

export const useFetchBlock = (reportId: string, blockId: string) => {
  const { isFetching, data, error } = useQuery({
    queryKey: ["block", reportId, blockId],
    queryFn: () => fetchBlockApi(reportId, blockId),
  });

  return {
    isFetching,
    data,
    error,
  };
};

export const useUploadDataSource = () => {
  const { mutate: uploadDatasource } = useMutation({
    mutationFn: (formData: FormData) => uploadDataSource(formData),
  });

  return {
    uploadDatasource,
  };
};

export const useFetchBlockResult = (reportId: string, blockId: string, last_result: boolean, isRedoing: boolean) => {
  const { isFetching, data, error, isSuccess, refetch } = useQuery({
    queryKey: ["block", reportId, blockId],
    queryFn: () => fetchBlockResultApi(reportId, blockId, isRedoing),
    enabled: !last_result,
  });

  return {
    isFetching,
    data,
    error,
    isSuccess,
    refetch,
  };
};

export const useUpdateBlock = () => {
  const { mutate: updateBlock } = useMutation({
    mutationFn: (data: ICreateBlock) => updateBlockApi(data),
  });

  return {
    updateBlock,
  };
};

export const useChatQueries = () => {
  const { mutate: chatQuery } = useMutation({
    mutationFn: (data: any) => chatQueryApi(data),
  });

  return {
    chatQuery,
  };
};

export const useFetchBlockImage = (reportId: string, blockId: string, resultType: string, result: boolean) => {
  const { isFetching, data, error } = useQuery({
    queryKey: ["blockImage", reportId, blockId],
    queryFn: () => fetchBlockImageApi({ reportId, blockId }),
    enabled: resultType === IResultTypes.IMAGE && result,
  });

  return {
    isFetching,
    data,
    error,
  };
};

export const useDeleteReports = () => {
  const { mutate: deleteReports } = useMutation({
    mutationFn: (report_ids: number[]) => deleteReportApi(report_ids),
  });

  return {
    deleteReports,
  };
};

export const useFetchBlockReferences = (reportId: string, blockId: string) => {
  const { isFetching, data, error } = useQuery({
    queryKey: ["references", reportId, blockId],
    queryFn: () => fetchBlockReferencesApi({ reportId, blockId }),
  });

  return {
    isFetching,
    data,
    error,
  };
};

export const useGetReportDataSources = (report_id: string) => {
  const { isFetching, data, error } = useQuery({
    queryKey: ["report-datasources", report_id],
    queryFn: () => getReportDataSourcesApi(report_id),
  });

  return {
    isFetching,
    data,
    error,
  };
};

export const useGetReportDetails = (report_id: string) => {
  const { isFetching, data, error } = useQuery({
    queryKey: ["report-details", report_id],
    queryFn: () => getReportDetails(report_id),
  });

  return {
    isFetching,
    data,
    error,
  };
};
